import React, {useEffect, useState} from 'react';
import {SelectBlock} from "../atoms";
import {withTheme} from "styled-components";
import {Select, Spin} from 'antd';
import debounce from 'lodash/debounce'
import {useHistory} from "react-router-dom";

const {Option} = Select;

const SelectSystemView = (props) => {
    const {
        options,
        status,
        theme,
        label,
        placeholder,
        change,
        value,
        onSearch,
        loading,
        disabled,
        style
    } = props;


    const [valueSelect, setValueSelect] = useState({value: undefined});

    useEffect(() => {
        if (value) {
            setValueSelect({
                value: value
            })
        } else {
            setValueSelect(undefined);
        }
    }, [value]);

    const searchChange = (e) => {
        onSearch(e);
    };


    return (
        <SelectBlock status={status ? status : 'default'} theme={theme.main} style={style}>
            {
                label &&
                <label>{label}</label>
            }
            <Select
                labelInValue
                showSearch
                filterOption={false}
                onSearch={debounce(searchChange, 800)}
                notFoundContent={loading ? <Spin size="small"/> : null}
                value={valueSelect}
                disabled={disabled ? disabled : false}
                onChange={(e) => change(e.value)}
                placeholder={placeholder}
                dropdownClassName='select-dropdown'
            >
                {
                    options && options.length > 0 ? options.map((item, idx) => {
                        return (
                            <Option key={idx} value={item.value}>{item.label ? item.label : item.name}</Option>
                        )
                    }) : null
                }
            </Select>
        </SelectBlock>
    )
};

export const SelectSystem = withTheme(SelectSystemView);


const SelectSysView = (props) => {
    const {push, location} = useHistory();
    const {
        disabled,
        loading,
        options,
        value,
        change,
        onSearch,
        mode,
        placeholder,
        label,
        status,
        theme,
        searchParams,
        style,
        labelInValue,
    } = props
    const [valueSelect, setValueSelect] = useState(undefined)
    const [$urlParams, setUrlParams] = useState(undefined)

    useEffect(() => {
        if (value) {
            setValueSelect(value)
        } else {
            setValueSelect(undefined)
        }
    }, [value])

    useEffect(() => {
        if (searchParams) {
            const paramsString = location.search;
            const $searchParams = new URLSearchParams(paramsString);
            $searchParams.delete('info')
            $searchParams.delete(searchParams.search)
            setUrlParams($searchParams.toString())
        }

    }, [location.search, searchParams])


    const handleChange = (data) => {

        change(data)
        if (searchParams) {
            const {pathname, search} = searchParams
            if (Array.isArray(data)) {
                if (data.length > 0) {
                    push({
                        pathname: pathname,
                        search: `info&${search}=${data.join(',')}${$urlParams && '&' + $urlParams}`
                    })
                } else {
                    push({
                        pathname: pathname,
                        search: `info${$urlParams && '&' + $urlParams}`
                    })
                }
            } else {
                if (data) {
                    push({
                        pathname: pathname,
                        search: `info&${search}=${data}${$urlParams && '&' + $urlParams}`
                    })
                } else {
                    push({
                        pathname: pathname,
                        search: `info${$urlParams && '&' + $urlParams}`
                    })
                }
            }

        }
    }

    const searchChange = (e) => {
        onSearch(e);
    };


    return (
        <SelectBlock status={status ? status : 'default'} theme={theme.main} style={style}>
            {
                label &&
                <label>{label}</label>
            }
            <Select
                showSearch
                filterOption={false}
                disabled={disabled ? disabled : false}
                onSearch={debounce(searchChange, 800)}
                loading={loading}
                mode={mode}
                value={valueSelect}
                placeholder={placeholder}
                // options={options}
                onChange={(e) => handleChange(e)}
                dropdownClassName='select-dropdown'
                labelInValue={labelInValue ? labelInValue : false}
            >
                {
                    options && options.length > 0 ? options.map((item, idx) => {
                        return (
                            <Option key={idx} value={item.value}>{item.label}</Option>
                        )
                    }) : null
                }
            </Select>
        </SelectBlock>
    )
}

export const SelectSys = withTheme(SelectSysView)
