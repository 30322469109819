import React, {useEffect, useState, Fragment} from 'react'
import {ReportInfiniteScroll, ReportListWrapper, ReportModalTitle, ReportModalWrapper} from "../atoms";
import {Button, ModalFooterWrapper} from "../../../ui/atoms";
import {useStore} from "effector-react";
import {$report} from "../../../models/report-model";
import {ReportListsItem} from "./report-list-item";
import {ReportListTab} from "./report-list-tab";
import {useTranslation} from "react-i18next";

export const AttributeModal = ({title, handleAccept, setStatusModal}) => {
    const {$selectedIds, $reportAttributeList} = useStore($report)
    const [selected, setSelected] = useState(null)
    const [showIndex, setShowIndex] = useState(0)
    const {t} = useTranslation()

    const {data} = $reportAttributeList

    useEffect(() => {
        if (Object.values($selectedIds.attribute).length > 0) {
            setSelected($selectedIds.attribute)
        }else {
            setSelected(null)
        }
    }, [$selectedIds.attribute])

    const handleClick = () => {
        const data = {...selected}
        handleAccept({...$selectedIds, attribute: {...data}})
    }


    const load = () => {}

    const parents = Object.values($selectedIds.category)

    return (
        <ReportModalWrapper>
            <ReportModalTitle>
                {title}
            </ReportModalTitle>
            {
                parents.length > 0
                && (
                    <>
                        <ReportListTab
                            data={parents}
                            setShowIndex={setShowIndex}
                            showIndex={showIndex}
                        />
                        <ReportListWrapper>
                            {
                                parents.map((parent, idx) => (
                                    <Fragment key={`${idx + 1}`}>
                                        {
                                            showIndex === idx
                                            && (
                                                <ReportInfiniteScroll
                                                    pageStart={0}
                                                    loadMore={load}
                                                    hasMore={false}
                                                    loader={<div className="loader" key={0}>Loading ...</div>}
                                                    useWindow={false}
                                                    threshold={400}
                                                >
                                                    {
                                                        data[parent.id] && data[parent.id].map((item, x) => {
                                                            return (
                                                                <ReportListsItem
                                                                    key={`${x + 1}`}
                                                                    item={item}
                                                                    selected={selected}
                                                                    setSelected={setSelected}
                                                                    multipleSelect
                                                                >
                                                                </ReportListsItem>
                                                            )
                                                        })
                                                    }
                                                </ReportInfiniteScroll>
                                            )
                                        }

                                    </Fragment>
                                ))
                            }
                        </ReportListWrapper>
                    </>
                )
            }
            <ModalFooterWrapper>
                <Button
                    type='link'
                    onClick={() => setStatusModal(false)}
                >
                    {t('cancel')}
                </Button>
                <Button onClick={handleClick}>
                    {t('apply')}
                </Button>
            </ModalFooterWrapper>
        </ReportModalWrapper>
    )
}