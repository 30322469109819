import {httpGet} from "../api";


export const getOrganizationList = (params) => httpGet({
    url: '/organization/list', params
});

export const getOrganizationInfo = (id) => httpGet({
    url: `/organization/${id}`
});

export const getOrganizationSectorList = (id) => httpGet({
    url: `/sector/organization/${id}`
});

export const getMultipleSubSectorList = ({id, params}) => httpGet({
    url: `/sector/list/${id}`, params
})

export const getOrgUserList = (id) => httpGet({
    url: `/permission/${id}`
});