import { createStore, combine } from "effector";
import { fetchCategoryList } from "./effects";
import * as R from 'ramda'

const $categoryList = createStore({ loading: false, data: [], result: {}, error: false })
    .on(fetchCategoryList.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchCategoryList.finally, (state, res) => {
        if (res.error) {
            return {
                ...state,
                error: res.error.response,
                result: {},
                data: []
            }
        } else {
            return {
                ...state,
                result: res.result.data.data.map(item => ({
                    label: item.name,
                    value: item.id
                })),
                data: R.indexBy(R.prop('id'), res.result.data.data)
            }
        }
    })


// $categoryList.watch(console.log)

export const $category = combine({
    $categoryList
})
