import styled from 'styled-components';
import { getColor } from '../../helpers';
import { Button } from "./button";

// import { Link } from 'react-router-dom';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;
export const Svg = styled.svg`
    display: flex;
`;

export const Container = styled.div`
    padding: 0 24px;
    @media (max-width: 1440px) {
      padding: 0 16px;
    }
`;

export const GridWrapper = styled.div`
  display: grid;
`;

export const ErrorText = styled.div`
  font-weight: 600;
  font-size: 13px;
  color: #FF3D71;
`

export const GridBasic = styled(GridWrapper)`
  grid-template-columns: repeat(${props => props.perColumn}, 1fr);
  ${props => props.rowGap ? `grid-row-gap: ${props.rowGap}px;  grid-column-gap: ${props.columnGap}px` : `grid-column-gap: ${props.perColumn}px`}

  @media (max-width: 1440px) {
    ${props => props.rowGap && `grid-row-gap: 30px;`}
  }
`;

export const GridCustom = styled(GridWrapper)`
  grid-template-columns: ${props => props.perColumn};
  ${props => props.rowGap ? `grid-row-gap: ${props.rowGap}px;  grid-column-gap: ${props.columnGap}px` : `grid-column-gap: ${props.perColumn}px`}

  @media (max-width: 1440px) {
    ${props => props.rowGap && `grid-row-gap: 30px;`}
  }
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.gridColumn && `grid-column: ${props.gridColumn}`}
`;


export const InputBlock = styled.div`

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused{
        box-shadow: unset
    }

  .ant-input-affix-wrapper{
    border: 0 !important;
    padding: 0;  
  }
  
  .ant-input-suffix{
    margin: 0 !important;
  }

  display: flex;
  width: 100%;
  flex-direction: column;
  div{
    position: relative;
    svg{
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      margin: 0;
    }
  }
  input{
    padding: 11px ${props => props.icon ? '30px' : '16px'} 11px 16px !important;
    outline: none !important;;
    width: 100%;
    border: 1px solid ${props => getColor(props.status)} !important;
    border-radius: 8px;
    background: #FAFAFB;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #222B45;
    transition: .3s;
    &::placeholder{
      color: #8F9BB3;
    }
    &:hover{
      background: #EDF1F7;
    }
    &:focus{
      border: 1px solid #3366FF;
    }
    &:disabled{
      mix-blend-mode: normal;
      opacity: 0.48;
    }

    @media (max-width: 1440px) {
      padding: 8px ${props => props.icon ? '24px' : '16px'} 8px 16px !important;
    }
  }
  label{
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #8992A3;
    margin-bottom: 8px;
    min-height: 16px;
  }
  
  .ant-col {
    display: flex;
    flex-direction: column
  }
`;
export const SelectBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  & > div{
    position: relative;
    svg{
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }
  .css-1hwfws3{
    padding: 0;
  }
  .css-b8ldur-Input{
    display: none;
  }
  .css-1wa3eu0-placeholder, .css-1uccc91-singleValue{
    position: static;
    transform: none;
  }
  .css-1okebmr-indicatorSeparator{
    display: none;
  }
  .css-26l3qy-menu{
    margin: 0;
    box-shadow: none;
  }
  .css-1n7v3ny-option{
    background: #8f9bb314;
  }
  .ant-select{
    &--single{
      height: initial;
      border: none;
    }
    &__placeholder{
      padding: 0 10px;
      color: #8F9BB3;
    }
    &-selection{
      &-search{
        display: flex;
        align-items: center;
      }
    }
    &-selector{
      padding: 8px ${props => props.icon ? '30px' : '16px'} 8px 16px !important;
      outline: none;
      width: 100%;
      border: 1px solid ${props => getColor(props.status)} !important;
      border-radius: 8px !important;
      background: #FAFAFB !important;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #222B45;
      transition: .3s;
      height: initial !important;
      margin: 0;
      flex-wrap: nowrap !important ;
      overflow: hidden; 
      cursor: pointer !important; 
      //scrollbar-color: #d3d3d3 #fff;
      //scrollbar-height: thin;

      @media (max-width: 1440px){
        padding: 5px ${props => props.icon ? '24px' : '16px'} 7px 16px !important;
      }

          
      //&::-webkit-scrollbar {
      //  height: 6px;
      //}
      //
      //&::-webkit-scrollbar-thumb {
      //  border-radius: 4px;
      //  background-color: #d3d3d3;
      //}
       
      &::placeholder{
        color: #8F9BB3;
      }
      &:hover{
        background: #EDF1F7;
        border-color: ${props => getColor(props.status)};
      }
      &:focus{
        border: 1px solid #3366FF;
      }
      &:disabled{
        mix-blend-mode: normal;
        opacity: 0.48;
      }
    }
  }

  .ant-select-search--inline .ant-select-search__field{
    height: initial;
  }

  label{
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #8992A3;
    margin-bottom: 8px;
  }
  
  .ant-select-multiple .ant-select-selection-item-remove{
    width: 22px
  }
`;
export const HightBlockSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  background: #fafafb;
  width: calc(100vw - 296px);
  margin-left: 296px;
  
  @media (max-width: 1440px) {
    width: calc(100vw - 220px);
    margin-left: 220px;
  }
  
  @media (max-width: 1280px){
    width: 100%;
    margin-left: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  padding-top: 184px;
  background: #fafafb;
  min-height: 100vh;
  @media (max-width: 1440px) {
    padding-top: 130px;
  }
`

export const Block = styled.div`
  display: flex;
  width: ${props => props.span ? props.span : '100%'};
  flex-direction: column;
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.main.themeColor[100]};
  border: 1px solid ${({ theme }) => theme.main.themeColor[300]};;
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 32px 24px 48px;
  @media (max-width: 1440px) {
    padding : 32px 24px;
  }
`

export const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${props => props.gutter ? `0 -${props.gutter}px` : '0'};
  ${Block}{
    padding: ${props => props.gutter ? `0 ${props.gutter}px` : 0};
  }
`;
export const ButtonCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 48px;
`;
export const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;
export const InlineFlex = styled.div`
  display: inline-flex;
`;
export const GridColumn = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.column}, max-content);
    grid-gap: ${props => props.gap}px;
    ${Button} {
      padding: 0 20px;
      
     @media (max-width: 1280px) {
      padding: 0 12px;
     }
    }
`
export const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
export const ColFlex = styled.div`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
  padding: ${props => props.padding ? `0 ${props.padding}px` : 0};
`;

export const BorderBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EDF1F7;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0 0 24px;
`;

export const TableHead = styled.div`
  display: flex;
  width: 100%;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #8F9BB3;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

export const TableItem = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #EDF1F7;
  transition: .3s;
  &:hover, &:focus{
    background: #EDF1F7;
  }
  a{
      text-decoration: none;
      color: #222B45;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    
  }
`;

const alert = styled.div`
  line-height: 1.4;
  font-size: 13px;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
`

export const AlertWarning = styled(alert)`
  background-color: ${({ theme }) => theme.main.semanticColor.warning[200]};
  border: 1px solid ${({ theme }) => theme.main.semanticColor.warning[400]};
  color: ${({ theme }) => theme.main.semanticColor.warning[700]};
`
