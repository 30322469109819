import styled from 'styled-components';
import {Modal} from "antd";


export const ModalCustom = styled(Modal)`
  width: 800px !important;
  .ant{
    &-modal{
      &-close{
        display: none;
      }
      &-header{
        display: none;
      }
    }
  }
`;

export const MiniModalCustom = styled(Modal)`
    width: ${({width}) => width ? width : 425}px !important;
  .ant{
    &-modal{
      &-close{
        display: none;
      }
      &-header{
        display: none;
      }
    }
  }
  
  .ant-modal-body{
    padding: 32px
  }  
`

export const CropModalCustom = styled(Modal)`
    width: 1100px !important;
  .ant{
    &-modal{
      &-close{
        display: none;
      }
      &-header{
        display: none;
      }
    }
  }
  
  .ant-modal-body{
    padding: 32px
  }  
`

export const LargeModalCustom = styled(Modal)`
    width: 95vw !important;
    
    .ant-modal-close {
        top: -40px;
        color: ${({theme}) => theme.main.themeColor[100]}
    }
    
    .ant-modal-close-x {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1{
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #8F9BB3;
  }
  & > div > span{
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #8992A3;
    margin-right: 15px;
  }
`;


export const ModalBody = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonBlockFlex = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const ModalFooterWrapper = styled.div`
    display: flex;
    justify-content: center
    
    button:first-child {
        margin-right: 24px
        color: ${({theme}) => theme.main.themeColor[700]}
    }
`

export const DropWrapper = styled.div`
    width: 100%;
    min-height: 350px
    border: 2px dashed ${({theme}) => theme.main.semanticColor.danger[500]}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    margin-bottom: 30px;
    padding: 20px
    font-weight: 600;
     
    div {
        font-size: 20px;
        font-weight: bold
        color: ${({theme}) => theme.main.semanticColor.basic[800]}
    }
    
    span {
        margin: 10px 0 
    }
    
    strong {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        color: ${({theme}) => theme.main.semanticColor.danger[500]};
        text-align: center;
    }
`

export const CropWrapper = styled.div`
    display: flex;
    justify-content: center;
`
