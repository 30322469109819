import React, {Fragment} from 'react'
import {useStore} from "effector-react";
import {$report, changeCommonReportSlideDown} from "../../../models/report-model";
import {ArrowBottomSvg} from "../../../media";
import {numberFormat} from "../../../helpers";
import moment from "moment";
import {InfoGrid, ReportCatTitle, SummaryItem, ValueInner, ValueItem, ValueWrapper} from "../atoms";
import {Spinner} from "../../../ui/molecules";
import {SlideDown} from 'react-slidedown'
import {Empty} from "antd";

export const ReportCommonSummary = () => {
    const {$allSummary} = useStore($report)
    const {loading, data, slideDown, show} = $allSummary

    const getValue = ({length, value}, type) => {
        switch (type) {
            case 'DATE' :
                return `${numberFormat(length / 1000)} км - ${moment(new Date(value)).format('YYYY')}г`
            default:
                return `${numberFormat(length / 1000)} км - ${value}`
        }
    }

    const handleClick = (id) => {
        const data = {}
        data[id] = !slideDown[id]
        changeCommonReportSlideDown(data)
    }

    return (
        <>
            {loading && <Spinner/>}
            {
                !loading && data.length > 0 && data.map((item) => {
                    return (
                        <Fragment key={item.category.id}>
                            {
                                show[item.category.id]
                                && (
                                    <>
                                        <ReportCatTitle
                                            status={!slideDown[item.category.id]}
                                            onClick={() => handleClick(item.category.id)}
                                        >
                                            <div>
                                                {item.category.name}
                                                <ArrowBottomSvg/>
                                            </div>
                                        </ReportCatTitle>
                                        <SlideDown closed={slideDown[item.category.id]}>
                                            {item.data.length > 0 && item.data.map(({attribute, values}) => (
                                                <InfoGrid key={attribute.id}>
                                                    <SummaryItem title>{attribute.name}</SummaryItem>
                                                    <SummaryItem>
                                                        {
                                                            values.length > 0
                                                                ? (
                                                                    <>
                                                                        {
                                                                            values.length === 1
                                                                                ? values.map((value, idx) => (
                                                                                    <Fragment key={`${idx + 1}`}>
                                                                                        {getValue(value, attribute.type)}
                                                                                    </Fragment>
                                                                                ))
                                                                                : <ValueWrapper>
                                                                                    {
                                                                                        values.map((value, idx) => (
                                                                                            <ValueItem key={`${idx + 1}`}>
                                                                                                <ValueInner>
                                                                                                    {getValue(value, attribute.type)}
                                                                                                </ValueInner>
                                                                                            </ValueItem>
                                                                                        ))
                                                                                    }
                                                                                </ValueWrapper>
                                                                        }

                                                                    </>
                                                                ) : 'нет-данных'
                                                        }
                                                    </SummaryItem>
                                                </InfoGrid>
                                            ))}
                                        </SlideDown>
                                    </>
                                )
                            }
                        </Fragment>
                    )
                })
            }

            {
                !loading && data.length === 0
                && (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>)
            }
        </>
    )
}