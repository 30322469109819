import React, {useState} from 'react'
import {$report} from '../../../models/report-model'
import {useStore} from 'effector-react'

import moment from 'moment'

export const SummaryInfoItem = ({value}) => {
  const {$detailSummary} = useStore($report)
  const {data} = $detailSummary
  const [hide, setHide] = useState(true)

  const renderValue = () => {
    let tmp = []
    if (data[value.id] && data[value.id][value.start]) {
      const values = data[value.id][value.start]
      const km = value.start * 1000

      for (let i = 0; i < values.length; i++) {
        const color = values[i].colorRed && values[i].entry.type === "LONG" ? 'red' : null
        if (values[i].toMeter - values[i].fromMeter === 1000) {
          tmp.push({
            value: values[i].entry.name !== null && values[i].entry.name ? values[i].entry.name : values[i].entry.type === 'DATE' ? `${moment(new Date(values[i].entry.value)).format('YYYY')}г` : values[i].entry.value,
            color: color
          })
        } else if (values[i].toMeter - values[i].fromMeter > 0) {
          tmp.push({
            value: `${values[i].fromMeter - km} - ${values[i].toMeter - km} м - ${values[i].entry.name !== null && values[i].entry.name ? values[i].entry.name : values[i].entry.type === 'DATE' ? `${moment(new Date(values[i].entry.value)).format('YYYY')}г` : values[i].entry.value}`,
            color: color
          })
        }
      }
    }

    return tmp
  }

  return (
      <div>{renderValue().length > 0
          ? renderValue().length === 1 ? renderValue().map((item, idx) => (
                  <div
                      key={idx}
                      style={{color: item.color ? 'red' : 'inherit'}}>{
                    item.value}
                  </div>
              ))
              : hide
                  ? <>
                    <div style={{color: renderValue()[0].color ? 'red' : 'inherit'}}>
                      {renderValue()[0].value}
                    </div>
                    <span onClick={() => setHide(false)}>ещё</span>
                  </>
                  : <>
                    {
                      renderValue().map((item, idx) => (
                          <div
                              key={idx}
                              style={{color: item.color ? 'red' : 'inherit'}}>{
                            item.value}
                          </div>
                      ))
                    }
                    <span onClick={() => setHide(true)}>скрыть</span>
                  </>
          : ''}
      </div>
  )
}