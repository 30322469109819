import styled from 'styled-components';
import { InputBlock } from "../../../ui/atoms";

export const SignInBlock = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${props => props.background[400]};
  min-height: 100vh;
  width: 100%;
  @media (max-width:1440px) {
    svg {
      width:150px;
      height: 55px
    }
  }
`;
export const FormBlock = styled.div`
  background: #FFFFFF;
  border-radius: 8px;
  padding: 56px;
  margin-top: 84px;
  min-width: 450px;
  max-width: 450px;
  ${InputBlock}{
    margin-bottom: 40px;
  }
  svg {
    width: inherit;
    height: inherit
  }
  @media (max-width: 1440px) {
    padding: 40px;
    margin-top: 64px;
  }
`;


export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CountDownWrap = styled.div`
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  font-size: 14px;

  div{
    cursor: pointer;
  }

  .ant-statistic-content {
    font-size: 14px !important;
  }
`
