import React from 'react';
import {ImageLoad} from "../../download";
import {HeaderAvatarBlock} from "../atoms";
import admin from '../../../media/admin.png'

export const HeaderAvatar = (props) => {
    const {
        id
    } = props;

    return (
        <HeaderAvatarBlock>
            {id ?
                <ImageLoad id={id} />
                : <img src={admin} alt="admin"/>
            }

        </HeaderAvatarBlock>
    )
};