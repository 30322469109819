import styled from "styled-components";

export const InfoTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: #222B45;
  text-transform: uppercase;
`

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  margin-top: 24px;
`

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
`

InfoItem.Title = styled.div`
  color: ${({theme}) => theme.main.semanticColor.basic[600]};
`
InfoItem.Value = styled.div`
  color: ${({theme}) => theme.main.semanticColor.basic[800]};
`

export const DropdownList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  max-height: 350px;

  &::-webkit-scrollbar {
    width: 5px
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;

  }

  div {
    padding: 14px;
    border: 1px solid #EDF1F7;
    color: #222B45;
    font-size: 14px;
    max-width: 365px;
    min-width: 280px;
    cursor: pointer;

    &:hover {
      background-color: #f7f7f7;
    }

    small {
      font-size: 14px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical
    }
  }
`

export const UserListInfoHeading = styled.div`
  padding: 24px 0;

  @media (max-width: 1440px) {
    padding: 18px;
  }

  h4 {
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    color: ${({theme}) => theme.main.semanticColor.basic[800]};
    text-transform: uppercase;
    margin-bottom: 0;
    white-space: nowrap;

    @media (max-width: 1440px) {
      font-size: 20px;
    }
  }

  input {
    padding: 11px 15px !important;
    background: none;
  }

  .ant-select-selector {
    padding: 8px 15px !important;
    background: none !important;

    input {
      padding: 0 !important;
    }
  }
`

export const UserListInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 50px;
  padding: 24px;
  overflow-y: auto;
  max-height: 70vh;
  min-height: 70vh;
  scrollbar-color: ${({theme}) => theme.main.semanticColor.basic[600]} #fff;
  scrollbar-width: thin;

  @media (max-width: 1440px) {
    padding: 12px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${({theme}) => theme.main.semanticColor.basic[600]};
  }
`

export const UserListInfo = styled.div`
  display: grid;
  grid-template-columns: 180px 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  @media (max-width: 1440px) {
    grid-row-gap: 10px;
  }
`

export const UserListInfoItem = styled.div`
  display: grid;
  grid-template-columns: 67px 1fr;
  grid-column-gap: 50px;
`

export const UserListInfoField = styled.div`
  color: ${({theme}) => theme.main.semanticColor.basic[600]};
`

export const UserListInfoValue = styled.div`
  color: ${({theme}) => theme.main.semanticColor.basic[800]};
`

export const UserAvatar = styled.div`
  overflow: hidden;
  position: relative;
  border: 1px solid ${({theme}) => theme.main.semanticColor.basic[300]};
  width: 67px;
  height: 67px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // span {
  //     display: inline-block;
  //     position: absolute;
  //     width: 13px;
  //     height: 13px;
    //     border: 2px solid ${({theme}) => theme.main.themeColor[100]}
    //     background-color: ${({theme}) => theme.main.semanticColor.success[500]};
  //     right: 3px;
  //     bottom: 1px;
  //     border-radius: 50%;
  // }

  img {
    object-fit: contain;
    display: block;
    max-width: 100%;
    height: auto;
  }
`

export const UserCountButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FAFAFB;
  border: 1px solid #E4E9F2;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 2px 4px;
  min-width: 24px;
  min-height: 26px;,
  cursor: pointer;
`