import React, {Fragment} from 'react'
import {useStore} from "effector-react";
import {$report, changeCommonReportSlideDown} from "../../../models/report-model";
import {ArrowBottomSvg} from "../../../media";
import {
    DetailInfoContainer,
    InfoFlex,
    InfoGrid,
    InfoHeader,
    ReportCatTitle,
    ReportTable,
    SummaryItem
} from "../atoms";
import {SlideDown} from "react-slidedown";
import {Spinner} from "../../../ui/molecules";
import {Empty} from "antd";
import {SummaryInfoItem} from "./summary-item";

export const ReportDetailSummary = () => {
    const {$detailSummary} = useStore($report)
    const {thead, tbody, show, slideDown, data, loading, forceLoading} = $detailSummary

    const handleClick = (id) => {
        const data = {}
        data[id] = !slideDown[id]
        changeCommonReportSlideDown(data)
    }

    console.log($detailSummary);

    return (
        <DetailInfoContainer>
            {forceLoading && <Spinner/>}
            {
                !loading && !forceLoading && Object.values(data).length > 0
                && (
                    <>
                        <InfoHeader>
                            {
                                thead.length > 0 &&
                                <ReportTable>
                                    {thead.map((item, idx) => (
                                        <InfoGrid key={idx}>
                                            <ReportCatTitle>{item.title}</ReportCatTitle>
                                            <InfoFlex>
                                                {
                                                    item.data.length > 0 && item.data.map((km, y) => (
                                                        <SummaryItem key={y} noBorder={true}>
                                                            <div>
                                                                {km}
                                                            </div>
                                                        </SummaryItem>
                                                    ))
                                                }
                                            </InfoFlex>
                                        </InfoGrid>
                                    ))}
                                </ReportTable>
                            }
                        </InfoHeader>
                        {
                            tbody.length > 0 &&
                            <ReportTable>
                                {
                                    tbody.map((item) => (
                                        <Fragment key={item.categoryId}>
                                            {
                                                show[item.categoryId]
                                                && (
                                                    <>
                                                        <InfoGrid>
                                                            <ReportCatTitle
                                                                status={!slideDown[item.categoryId]}
                                                                onClick={() => handleClick(item.categoryId)}
                                                            >
                                                                <div>
                                                                    {item.name}
                                                                    <ArrowBottomSvg/>
                                                                </div>
                                                            </ReportCatTitle>
                                                        </InfoGrid>
                                                        <SlideDown transitionOnAppear closed={slideDown[item.categoryId]}>
                                                            {
                                                                item.data.length > 0 &&
                                                                item.data.map((mainData, idx) => (
                                                                    <InfoGrid key={idx}>
                                                                        <SummaryItem title>
                                                                            {mainData.name}
                                                                        </SummaryItem>
                                                                        {
                                                                            mainData.data.length > 0
                                                                            && <InfoFlex>
                                                                                {
                                                                                    mainData.data.map((value, b) => (
                                                                                        <SummaryItem key={b}>
                                                                                            <SummaryInfoItem value={value}/>
                                                                                        </SummaryItem>
                                                                                    ))
                                                                                }
                                                                            </InfoFlex>
                                                                        }
                                                                    </InfoGrid>
                                                                ))
                                                            }
                                                        </SlideDown>
                                                    </>
                                                )
                                            }
                                        </Fragment>
                                    ))
                                }
                            </ReportTable>
                        }
                    </>
                )
            }
            {
                !loading && Object.values(data).length === 0
                && (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
            }
        </DetailInfoContainer>
    )
}