import React, {useEffect, useState} from 'react'
import {ReportListWrapper, ReportModalTitle, ReportModalWrapper} from "../atoms";
import {Button, ModalFooterWrapper} from "../../../ui/atoms";
import {useStore} from "effector-react";
import {$report} from "../../../models/report-model";
import {ReportListsItem} from "./report-list-item";
import {getEnd, getStart} from "../../../hooks/info/helper";
import {useTranslation} from "react-i18next";

export const StationSectorModal = ({title, handleAccept, setStatusModal}) => {
    const {$selectedIds, $reportStationSubSectorList} = useStore($report)
    const [selected, setSelected] = useState(null)
    const {data} = $reportStationSubSectorList
    const {t} = useTranslation()

    const handleClick = () => {
        const data = {...selected}
        const params = {
            ...$selectedIds,
            sub_sector: {},
            startValue: '',
            endValue: '',
            showDetail: true,
            station_sector: {...data}
        }

        if (Object.values(data).length === 1) {
            const obj = Object.values(data).find((item, idx) => idx === 0)
            const startValue = getStart(obj)
            const endValue = getEnd(obj)
            params['startValue'] = startValue
            params['endValue'] = endValue
        }
        handleAccept(params)
    }

    useEffect(() => {
        if (Object.values($selectedIds.station_sector).length > 0) {
            setSelected($selectedIds.station_sector)
        } else {
            setSelected(null)
        }
    }, [$selectedIds.station_sector])

    return (
        <ReportModalWrapper>
            <ReportModalTitle>
                {title}
            </ReportModalTitle>
            <ReportListWrapper>
                {
                    data.length > 0 && data.map(item => (
                        <ReportListsItem
                            multipleSelect={false}
                            key={item.id}
                            item={item}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    ))
                }
            </ReportListWrapper>
            <ModalFooterWrapper>
                <Button
                    type='link'
                    onClick={() => setStatusModal(false)}
                >
                    {t('cancel')}
                </Button>
                <Button onClick={handleClick}>
                    {t('apply')}
                </Button>
            </ModalFooterWrapper>
        </ReportModalWrapper>
    )
}