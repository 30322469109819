import styled from 'styled-components';
import {Tabs} from 'antd';


export const TabsCustom = styled(Tabs)`
    .ant-tabs-nav .ant-tabs-tab{
      padding: 22px;
      margin: 0;
      transition: .3s ease;
      border-left: 1px solid #EDF1F7;
      border-radius: 10px 0 0 10px;
      
      svg{
        color: #8F9BB3;
      }
    
      span{
        opacity: 0;
        visibility: hidden;
        font-size: inherit;
        transform: scale(0);
        display: none;
      }
    
      &.ant-tabs-tab-active {
        flex: 1;
        
        svg{
          color: #3366FF;
        }
    
        span {
          opacity: 1;
          visibility: visible;
          transform: scale(1);
          display: block;
        }
      }         
    }
    &.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane{
      transition: none !important;
    }
    @media (max-width: 1400px){
      .ant-tabs-nav .ant-tabs-tab{
        padding: 14px;
      }
    }
`;

export const TabsHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #101426;
  text-transform: uppercase;
  svg{
    margin-right: 10px;
  }
`;

export const NavLinksBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: initial;
  a{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 56px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    transition: .3s;
    padding: 0 50px;
    color: #8F9BB3;
    &:hover, &:focus, &.active{
      background: rgba(51, 102, 255, 0.08);
      color: #3366FF;
    }
  }
  @media(max-width: 1400px){
    a{
      height: 46px;
    }
  }
`;
