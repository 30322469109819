export const URL_ID = {
    SECTOR: 'sectorId',
    CATEGORY: 'categoryId',
    ATTRIBUTE: 'attributeId',
    SUB_SECTOR: 'subSectorId',
    ORGANIZATION: 'organizationId',
    STATION: 'stationId',
    STATION_SECTOR: 'stationSectorId'
}

export const URL_IDS = {
    SECTOR: 'sectorIds',
    CATEGORY: 'categoryIds',
    ATTRIBUTE: 'attributeIds',
    SUB_SECTOR: 'subSectorIds',
    ORGANIZATION: 'organizationIds',
    STATION: 'stationIds',
    STATION_SECTOR: 'stationSectorIds'
}

export const LISTS = {
    SECTOR: 'sectorList',
    CATEGORY: 'categoryList',
    ATTRIBUTE: 'attributeList',
    SUB_SECTOR: 'subSectorList',
    ORGANIZATION: 'organizationList',
    STATION: 'stationList',
    STATION_SUB_SECTOR: 'stationSubSector'
}

export const INFO = 'info'
