import {httpGet} from "../api";


export const getSectorInfo = (params) => httpGet({
    url: `/sector/list/${params.id}`, params
});

export const getSubSectorList = ({id, params}) => httpGet({
    url: `/sector/list/${id}`, params
});

export const getSectorList = (params) => httpGet({
    url: '/sector/list', params
})

export const getStationList = (id) => httpGet({
    url: `/station/list/${id}`
})

export const getSector = (id) => httpGet({
    url: `/sector/${id}`
})