import React, {useEffect, useState, useCallback} from "react";
import Cookies from "js-cookie";
import {useHistory} from "react-router-dom";
import {useStore} from "effector-react";
import {filterPointsEvent, getAttrInfoSector, googlePointPageMount, polyLineEvent} from "../../models/google-map";
import {getSectorInfo} from "../../features/dashboard/api";
import {getSubSectorList} from "../../api/sector-api";
import {styleMap} from "../../features/dashboard/helpers";
import {useUrlParams} from "../common";
import {getCategoryAttrListEvent} from "../../models/info-table-model/event";
import {$isDataPending} from "../../models/categories-attr-model";
import {$googlePoints} from '../../models/google-map';
import {attrCategoryMount} from "../../models/categories-attr-model/events";
import {useIndexedDB} from "react-indexed-db";
import {checkOrganizationEvent, filterParentPointsEvent, setDBPoints} from "../../models/google-map/events";
import {openNotificationWithIcon} from "../../helpers";
import {useTranslation} from "react-i18next";
import {$organization} from "../../models/organization-model";
import {$isDataPending as stationData} from "../../models/station-model";



export const GetPointsHooks = (props) => {
    const history = useHistory();
    const {t} = useTranslation();

    const {getAll} = useIndexedDB('googlePoints');

    const {
        sectorId,
        categoryId,
        attributeId,
        urlParams,
        organizationId,
        stationView
    } = useUrlParams(history.location, ['attributeId']);

    const isDataPending = useStore($isDataPending);
    const stationList = useStore(stationData).$stationList.data;

    const points = useStore($googlePoints);
    const organization = useStore($organization);
    const organizationData = organization.$organizationsSectorsList.data;



    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [centerWindow, setCenterWindow] = useState({
        lat: 41.259332,
        lng: 64.765053
    });
    const [centerInfoWindow, setCenterInfoWindow] = useState({
        lat: 41.259332,
        lng: 64.765053
    })
    const [sectorInfoData, setSectorInfoData] = useState([]);
    const [zoom, setZoom] = useState(6);
    const [attrStatus, setAttrStatus] = useState(false);
    const [clickSectorId, setClickSectorId] = useState(false);
    const [categoryName, setCategoryName] = useState(undefined)


    useEffect(() => {
        const tokenTime = Cookies.get('tokenTime')
        const addDays = 1;
        let date = new Date()
        date = date.setDate(date.getDate() + addDays);
        if (tokenTime === undefined || date < Number(tokenTime)) {
            const data = {
                ne: {
                    lat: 100,
                    long: 100
                },
                sw: {
                    lat: 0,
                    long: 0
                }
            };
            googlePointPageMount(data);
        } else {
            getAll().then(points => {
                setDBPoints(JSON.parse(points[0].pointArray))
            });
        }

    }, []);

    useEffect(() => {
        if (sectorId && points.filter) {

            const testSectors = {}


            const sectors = {}
            const parentSectors = {}

            points.data.map(item => {
                if (item.id === Number(sectorId)){
                    sectors[item.id] = item
                } else if (item.parentId === Number(sectorId)){
                    parentSectors[item.id] = item
                }
            });

            // Object.keys(points.filter).map(index => {
            //     if (points.filter[index].id === Number(sectorId)){
            //         sectors[index] = points.filter[index]
            //     }
            // });

            const selectSector = sectors[Object.keys(sectors)[Math.floor(Object.keys(sectors).length / 2)]]

            if (selectSector) {
                setCenterWindow(selectSector.points[Math.floor(selectSector.points.length / 2)])
                if (zoom < 10) {
                    setZoom(10);
                }
                setShowingInfoWindow(false)
                filterPointsEvent(sectors);
                filterParentPointsEvent(parentSectors)
                if (attributeId && parentSectors && Object.keys(parentSectors).length > 0) {
                    for (const [key, value] of Object.entries(parentSectors)) {
                        const data = {
                            id: attributeId,
                            sectorId: value.id,
                            fromM: value.points[0].kilo * 1000,
                            toM: value.points[value.points.length - 1].kilo * 1000 + 1000,
                        };
                        getAttrInfoSector(data);
                    }
                }
            } else {
                openNotificationWithIcon('error', t('error'), t('no-points'))
            }

        } else if (points.defaultFilter && points.defaultFilter.length > 0) {
            filterPointsEvent(points.defaultFilter);
            setZoom(6)
            setShowingInfoWindow(false)
            setCenterWindow({
                lat: 41.259332,
                lng: 64.765053
            })
        }
    }, [points.data, sectorId, attributeId]);

    useEffect(() => {
        setZoom(6)
    }, [organizationId])

    useEffect(() => {
        if (!sectorId && points.filter && Object.keys(points.filter).length > 0 && organizationData.length > 0) {
            checkOrganizationEvent(organizationData)
        }

    }, [points.defaultFilter, organizationData])


    useEffect(() => {
        setAttrInfo(categoryId);
    }, [categoryId]);


    useEffect(() => {
        isDataPending.$dashboardAttrList.data.filter(item => {
            if (item.id === Number(categoryId)) {
                getCategoryAttrListEvent(item)
            }
        })
        const categoryArray = isDataPending.$dashboardCategoriesList.data
        if (categoryArray && categoryArray.data && categoryArray.data.length > 0) {
            categoryArray.data.filter(item => item.id === Number(categoryId) && setCategoryName(item.name))
        }

    }, [categoryId, isDataPending.$dashboardAttrList.data]);

    useEffect(() => {
        if (stationList && stationList.length > 0 && points && points.defaultFilter){
            const data = {
                stationList: stationList,
                points: points.defaultFilter
            }
            polyLineEvent(data)
        }
    }, [stationList, points])


    const onPolylineClick = (props, e, info) => {
        setCenterInfoWindow({
            lat: props.path[Math.floor(props.path.length / 2)].lat,
            lng: props.path[Math.floor(props.path.length / 2)].lng
        });

        // setShowingInfoWindow(true);
        // history.push(`/?sectorId=${info.id}`);
        setClickSectorId(info.id);

        getSectorInfo(info.id)
            .then(response => {
                if (response.status === 200) {
                    const params = {
                        id: info.id,
                        params: {
                            length: 40,
                            start: 0
                        }
                    }
                    getSubSectorList(params)
                        .then(res => {
                            if (res.status === 200) {
                                setShowingInfoWindow(true);
                                setSectorInfoData({
                                    ...response.data,
                                    kilo: props.text,
                                    subsLength: res.data.data.length
                                });
                            }
                        })
                        .catch(error => {
                            setShowingInfoWindow(true);
                            setSectorInfoData({
                                ...response.data,
                                kilo: props.text,
                                subsLength: 0
                            });
                        })
                }
            })
            .catch(error => {
                console.error(error.response)
            })

    };

    const setAttrInfo = useCallback((categoryId) => {
        if (categoryId && isDataPending.$dashboardAttrList.data.filter(item => item.id === Number(categoryId)).length === 0) {
            attrCategoryMount(Number(categoryId));
        }
    }, [isDataPending.$dashboardAttrList.data]);

    const mapLoaded = (mapProps, map) => {
        let controlDiv = document.createElement('div');
        // var myControl = new MyControl(controlDiv);
        controlDiv.index = 1;
        controlDiv.title = 'Click to recenter the map';
        // let transitLayer = new props.google.maps.TransitLayer();
        // transitLayer.setMap(map);
        // map.controls[props.google.maps.ControlPosition.BOTTOM_LEFT].push(controlDiv);
        map.setOptions({
            styles: styleMap
        });
    };


    const symbolThree = {
        path: props.google.maps.SymbolPath.CIRCLE,
        scale: 4,
        fillColor: "#fff",
        strokeColor: '#222B45',
        fillOpacity: 1,
        strokeWeight: 2,
        zIndex: 2
    };
    const parentSymbol = {
        path: props.google.maps.SymbolPath.CIRCLE,
        scale: 4,
        fillColor: "#3366FF",
        // strokeColor: '#3366FF',
        fillOpacity: 1,
        strokeWeight: 0,
        zIndex: 2
    };

    const zoomChange = (mapProps, map) => {
        setZoom(map.zoom);
    };

    return {
        mapLoaded,
        centerWindow,
        showingInfoWindow,
        sectorInfoData,
        onPolylineClick,
        sectorId,
        parentSymbol,
        symbolThree,
        attributeId,
        urlParams,
        zoomChange,
        zoom,
        attrStatus,
        setAttrStatus,
        setShowingInfoWindow,
        clickSectorId,
        categoryName,
        centerInfoWindow,
        stationView
    }
};