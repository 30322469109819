import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {SignInPage, RestorePage} from "./features/sign-in";
import {ThemeProvider} from "styled-components";
import {theme} from './theme';
import 'antd/dist/antd.css';
import './App.scss';
import {Dashboard} from "./features/dashboard";
import {ConfigProvider} from "antd";
import {LastLocationProvider} from 'react-router-last-location';
import ruRU from 'antd/es/locale/ru_RU'
import ReactGA from 'react-ga';
// import {initDB} from "react-indexed-db";
// import {DBConfig} from "./DBConfig";


export const App = () => {

    // initDB(DBConfig);


    ReactGA.initialize('UA-83267813-9');

    return (
        <ThemeProvider theme={theme}>
            <ConfigProvider locale={ruRU}>
                <Router basename='/'>
                    <LastLocationProvider>
                        <Switch>
                            <Route path="/sign-in" component={SignInPage}/>
                            <Route path="/restore" component={RestorePage}/>
                            <Route exact
                                   path='/'
                                   component={Dashboard}/>
                            {/*<Route component={HightBlock}/>*/}
                        </Switch>
                    </LastLocationProvider>
                </Router>
            </ConfigProvider>
        </ThemeProvider>


    );
};
