import {useHistory} from "react-router-dom";
import {useUrlParams} from "../common";
import {useCallback, useEffect, useState} from "react";
import {arrayDiff, stringToArray} from "../../helpers";
import {LISTS, URL_ID, URL_IDS, INFO} from "../../constants/common";
import {DASHBOARD} from "../../constants/route";
import {USER_ROLES} from "../../constants/user";
import {getAttributeCategory} from '../../api/category-api'
import {getSubSectorList, /*getSectorList*/} from "../../api/sector-api";
import {useStore} from "effector-react";
import {$organization} from "../../models/organization-model";
import {$userModel} from "../../models/user-model";
import {categoryListMount} from "../../models/category-model";
import {getStationList} from "../../api/station-api";

export const useInfoForm = (showAll, orgAllSubSectorList, allStationSector) => {
    /*hooks*/
    const {location, push} = useHistory()
    const {
        organizationId,
        sectorId,
        subSectorId,
        categoryId,
        attributeId,
        stationId,
        urlParams
    } = useUrlParams(location, ['sectorId'])

    const {$userRole} = useStore($userModel)
    const {$organizationsSectorsList} = useStore($organization)

    /*local states*/
    const [sectorLoading, setSectorLoading] = useState(false)
    const [subSectorLoading, setSubSectorLoading] = useState(false)

    const [attributeLoading, setAttributeLoading] = useState(false)
    const [stationLoading, setStationLoading] = useState(false)
    const [sectorList, setSectorList] = useState([])
    const [sectorIds, setSectorIds] = useState(undefined)
    const [subSectorList, setSubSectorList] = useState([])
    const [subSectorIds, setSubSectorIds] = useState(undefined)
    const [categoryIds, setCategoryIds] = useState(undefined)
    const [attributeIds, setAttributeIds] = useState(undefined)
    const [attributeList, setAttributeList] = useState([])
    const [categorySearch, setCategorySearch] = useState(undefined)
    const [showRange, setShowRange] = useState(true)
    const [startValue, setStartValue] = useState(0)
    const [endValue, setEndValue] = useState(0)
    const [dateValue, setDateValue] = useState(Date.now())
    const [showStation, setShowStation] = useState(false)
    const [stationIds, setStationIds] = useState(undefined)
    const [stationList, setStationList] = useState([])
    const [allowedStart, setAllowedStart] = useState(undefined)
    const [allowedEnd, setAllowedEnd] = useState(undefined)
    const [check, setCheck] = useState(false);
    const [showDetail, setShowDetail] = useState(false)
    const [sectorStationIds, setSectorStationIds] = useState(undefined)
    const [sectorStationList, setSectorStationList] = useState([])

    const filterArrFields = (arr, parentId) => (
        arr.map(item => ({
            value: item.id,
            label: item.name,
            length: item.length && item.length,
            startPoint: item.startPoint && item.startPoint,
            countdownPoint: item.countdownPoint && item.countdownPoint,
            parentId: parentId ? parentId : item.parent && item.parent.id
        }))
    )

    const getOnlyId = (arr) => {
        let tmp = []

        for (let i = 0; i < arr.length; i++) {
            tmp.push(arr[i].value)
        }

        return tmp
    }

    const getAllowed = useCallback(() => {
        let start = []
        let end = []

        if (sectorId) {
            let x
            let y

            if (showStation) {
                if (sectorStationIds !== undefined) {
                    x = [sectorStationIds]
                    y = sectorStationList
                } else {
                    if (sectorIds !== undefined && sectorIds.length > 0) {
                        x = getOnlyId(subSectorList)
                        y = subSectorList
                    }
                }

            } else {
                if (subSectorIds !== undefined && subSectorList.length > 0) {
                    x = subSectorIds
                    y = subSectorList
                } else {
                    if (sectorIds !== undefined && sectorIds.length > 0) {
                        x = getOnlyId(subSectorList)
                        y = subSectorList
                    }
                }
            }

            if (x && y) {
                for (let i = 0; i < y.length; i++) {
                    if (showRange) {
                        if (y[i].value === x[0]) {
                            setAllowedStart(y[i].startPoint * 1000 + y[i].countdownPoint)
                            setAllowedEnd(y[i].startPoint * 1000 + y[i].countdownPoint + y[i].length)
                            setStartValue(y[i].startPoint * 1000 + y[i].countdownPoint)
                            setEndValue(y[i].startPoint * 1000 + y[i].countdownPoint + y[i].length)
                        }
                    } else {
                        for (let j = 0; j < x.length; j++) {
                            if (x[j] === y[i].value) {
                                start.push(y[i].startPoint * 1000 + y[i].countdownPoint)
                                end.push(y[i].startPoint * 1000 + y[i].countdownPoint + y[i].length)
                            }
                            if (j + 1 === x.length) {
                                setStartValue(start)
                                setEndValue(end)
                            }
                        }
                    }
                }
            }
        }
    }, [showRange, subSectorIds, subSectorList, sectorId, sectorIds, sectorStationList, sectorStationIds, showStation])

    const deleteSublist = useCallback((ids, type, parentValue) => {
        let tmp = []
        let idType = ''
        let x = null
        let y = null
        let newIds = []

        if (type === LISTS.SUB_SECTOR) {
            idType = URL_ID.SUB_SECTOR
            x = subSectorList
            y = subSectorIds
        } else if (type === LISTS.ATTRIBUTE) {
            idType = URL_ID.ATTRIBUTE
            x = attributeList
            y = attributeIds
        }

        if (x && y) {
            for (let j = 0; j < x.length; j++) {
                if (ids !== x[j].parentId) {
                    tmp = [...tmp, {...x[j]}]
                }
                if (ids === x[j].parentId) {
                    newIds = [...newIds, x[j].value]
                }

                if (x.length === j + 1) {
                    let timeOut = null

                    if (type === LISTS.SUB_SECTOR) {
                        setSubSectorList(tmp)
                    } else if (type === LISTS.ATTRIBUTE) {
                        setAttributeList(tmp)
                    }

                    if (y !== undefined) {
                        const diff = arrayDiff(y, newIds, true)
                        timeOut = setTimeout(() => {
                            push({
                                pathname: DASHBOARD,
                                search: `${INFO}${organizationId ? `&${URL_ID.ORGANIZATION}=${organizationId}` : ''}${
                                    idType === URL_ID.SUB_SECTOR
                                        ? parentValue.length > 0 ? `&${URL_ID.SECTOR}=${parentValue.join(',')}` : ''
                                        : sectorIds && sectorIds.length > 0 ? `&${URL_ID.SECTOR}=${sectorIds.join(',')}` : ''}${
                                    idType === URL_ID.SUB_SECTOR
                                        ? diff.length > 0 ? `&${idType}=${diff.join(',')}` : ''
                                        : subSectorIds && subSectorIds.length > 0 ? `&${URL_ID.SUB_SECTOR}=${subSectorIds.join(',')}` : ''}${
                                    idType === URL_ID.ATTRIBUTE
                                        ? parentValue.length > 0 ? `&${URL_ID.CATEGORY}=${parentValue.join(',')}` : ''
                                        : categoryIds && categoryIds.length > 0 ? `&${URL_ID.CATEGORY}=${categoryIds.join(',')}` : ''}${
                                    idType === URL_ID.ATTRIBUTE
                                        ? diff.length > 0 ? `&${idType}=${diff.join(',')}` : ''
                                        : attributeIds && attributeIds.length > 0 ? `&${URL_ID.ATTRIBUTE}=${attributeIds.join(',')}` : ''}`
                            })
                            if (diff.length > 0) {
                                if (type === LISTS.SUB_SECTOR) {
                                    setSubSectorIds(diff)
                                    setSubSectorList(tmp)
                                } else if (type === LISTS.ATTRIBUTE) {
                                    setAttributeIds(diff)
                                    setAttributeList(tmp)
                                }
                            } else {
                                if (type === LISTS.SUB_SECTOR) {
                                    setSubSectorIds(undefined)
                                    setSubSectorList([])
                                } else if (type === LISTS.ATTRIBUTE) {
                                    setAttributeIds(undefined)
                                    setAttributeList([])
                                }
                            }
                            clearTimeout(timeOut)
                            timeOut = null
                        }, 0)

                    }
                }
            }
        }

    }, [subSectorList, push, organizationId, categoryIds, sectorIds, subSectorIds, attributeIds, attributeList])

    const getAttribute = useCallback(async (ids) => {
        let tmp = []
        if (ids.length > 0) {
            tmp = attributeList
            setAttributeLoading(true)
            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                try {
                    let res = await getAttributeCategory(id)
                    const newData = filterArrFields(res.data, ids[i])
                    tmp = [...tmp, ...newData]
                    setAttributeList(tmp)
                    setAttributeLoading(false)
                } catch (err) {
                    console.log(err.response);
                    setAttributeLoading(false)
                }
            }

        }
    }, [attributeList])

    const getStations = useCallback(async (sectorId) => {
        try {
            const res = await getStationList(sectorId)
            setStationList(filterArrFields(res.data))
        } catch (e) {
            console.log(e);
        }
    }, [])

    const getSubSector = useCallback(async (ids) => {
        let timeout = null;

        if (timeout) {
            clearTimeout(timeout)
            timeout = null
        }
        setCheck(true)

        let tmp = [];
        const orgSectorList = $organizationsSectorsList.data
        if ($userRole !== null && $userRole === USER_ROLES.EMPLOYEE) {
            for (let i = 0; i < ids.length; i++) {
                for (let j = 0; j < orgSectorList.length; j++) {
                    if (ids[i] === orgSectorList[j].id) {
                        const newData = orgSectorList[j].subSectors
                        tmp = [...tmp, ...newData]
                        setSubSectorList(tmp)
                    }
                }
            }
        } else if ($userRole !== null && ($userRole !== USER_ROLES.EMPLOYEE || $userRole !== USER_ROLES.MANAGER)) {
            tmp = subSectorList
            setSubSectorLoading(true)
            if (!organizationId) {
                if (ids && ids.length > 0) {
                    for (let i = 0; i < ids.length; i++) {
                        const params = {
                            id: ids[i],
                            length: 30,
                            start: 0,
                        }
                        try {
                            const res = await getSubSectorList(params)
                            const newData = filterArrFields(res.data.data)
                            tmp = [...tmp, ...newData]
                            if (ids.length === i + 1) {
                                setSubSectorList(tmp)
                                setSubSectorLoading(false)
                            }
                        } catch (err) {
                            setSubSectorLoading(false)
                        }
                    }
                }
            } else {
                tmp = []
                for (let i = 0; i < ids.length; i++) {
                    for (let j = 0; j < orgAllSubSectorList.length; j++) {
                        if (ids[i] === orgAllSubSectorList[j].parentId) {
                            tmp.push(orgAllSubSectorList[j])
                        }
                    }
                    if (ids.length === i + 1) {
                        setSubSectorList(tmp)
                        setSubSectorLoading(false)
                    }
                }
            }
        }
    }, [$userRole, organizationId, orgAllSubSectorList, $organizationsSectorsList.data])

    const getStationSector = useCallback((id) => {
        const tmp = []
        if ($userRole !== null && ($userRole !== USER_ROLES.EMPLOYEE || $userRole !== USER_ROLES.MANAGER)) {
            if (allStationSector.length > 0) {
                for (let i = 0; i < allStationSector.length; i++) {
                    if (Number(id) === allStationSector[i].stationId) {
                        tmp.push(allStationSector[i])
                    }
                }
                setSectorStationList(tmp)
            }
        }

    }, [allStationSector, $userRole])


    const subListAction = useCallback((ids, listType, type, action, newValue) => {
        if (action === 'add') {
            if (listType === LISTS.SUB_SECTOR) {
                getSubSector(ids)
            } else if (listType === LISTS.ATTRIBUTE) {
                getAttribute(ids)
            } else if (listType === LISTS.STATION) {
                getStations(ids)
            }
        } else if (action === 'delete') {
            deleteSublist(ids, listType, newValue)
        }

    }, [getSubSector, deleteSublist, getAttribute, getStations])

    const changeAction = useCallback((newValue, prevValue, type, listType) => {
        if (prevValue !== undefined) {
            let diff = null
            if (newValue.length > prevValue.length) {
                diff = arrayDiff(newValue, prevValue, true)
                subListAction(diff, listType, type, 'add')
            } else {
                diff = arrayDiff(newValue, prevValue, false)[0]
                subListAction(diff, listType, type, 'delete', newValue)
            }
        } else {
            subListAction(newValue, listType, type, 'add')
        }
    }, [subListAction])


    const getCategory = useCallback(async () => {
        const params = {
            'search.value': categorySearch,
            start: 0,
            length: 30,
        }

        categoryListMount(params)

    }, [categorySearch])


    const $changeData = (value, type, listType) => {
        if (type === URL_IDS.SECTOR) {
            let a = value
            if (showStation) {
                a = [value]
                changeAction(a, undefined, type, listType)
            } else {
                changeAction(a, sectorIds, type, listType)
            }
            setSectorIds(a)
        }

        if (type === URL_IDS.STATION) {
            setStationIds(value)
            getStationSector(value)
        }

        if (type === URL_IDS.SUB_SECTOR) {
            setSubSectorIds(value)
        }

        if (type === URL_IDS.CATEGORY) {
            setCategoryIds(value)
            changeAction(value, categoryIds, type, listType)
        }

        if (type === URL_IDS.ATTRIBUTE) {
            setAttributeIds(value)
        }

    }

    const changeDate = (date, dateString) => {
        setDateValue(date.valueOf())
    }

    useEffect(() => {
        if (!categoryIds) {
            if (categoryId) {
                const ids = stringToArray(categoryId, ',')
                getAttribute(ids)
                setCategoryIds(ids)
            }
        }
    }, [categoryId, categoryIds, getAttribute])

    const changeForm = useCallback((status) => {
        setShowStation(status)
        if (sectorIds && status) {
            setSectorIds([sectorIds[0]])
            push({
                pathname: DASHBOARD,
                search: `${sectorIds ? `sectorId=${sectorIds[0]}` : ''}${urlParams && '&' + urlParams}`
            })
        }
    }, [push, sectorIds, urlParams])

    useEffect(() => {
        if (sectorId) {
            setSectorIds(stringToArray(sectorId, ','))
            getSubSector(stringToArray(sectorId, ','))
            if (stringToArray(sectorId, ',').length === 1) {
                getStations(stringToArray(sectorId, ',')[0])
            }
        } else {
            setSectorIds(undefined)
            setSubSectorIds(undefined)
            setSubSectorList([])
            setAllowedEnd(undefined)
            setAllowedStart(undefined)
            setStationList([])
            setStationIds(undefined)
            setShowStation(false)
        }

    }, [sectorId, getSubSector, stationId, getStations])

    useEffect(() => {
        if (attributeId) {
            setAttributeIds(stringToArray(attributeId, ','))
        } else {
            setAttributeIds(undefined)
            setAllowedEnd(undefined)
            setAllowedStart(undefined)
        }
    }, [attributeId])

    useEffect(() => {
        if (subSectorId) {
            if (!stationId) {
                setSubSectorIds(stringToArray(subSectorId, ','))
            } else {
                setSubSectorIds(undefined)
            }

            if (stationId) {
                setSectorStationIds(Number(subSectorId))
            } else {
                setSectorStationIds(undefined)
            }

        } else {
            setSubSectorIds(undefined)
            setSectorStationIds(undefined)
        }
    }, [subSectorId, stationId])

    useEffect(() => {
        if (stationId) {
            setStationIds(Number(stationId))
            setShowStation(true)
            getStationSector(stationId)

        } else {
            setStationIds(undefined)
            setShowStation(false)
        }
    }, [stationId, getStationSector])

    useEffect(() => {
        const orgSectorList = $organizationsSectorsList.data
        if (organizationId) {
            if (orgSectorList.length > 0) {
                setSectorList(orgSectorList)
            }
            if ($userRole !== null && $userRole === USER_ROLES.EMPLOYEE) {
                if (sectorId) {
                    getSubSector(stringToArray(sectorId))
                }
            }
        } else {
            if (!sectorId) {
                setSectorIds(undefined)
                setSubSectorIds(undefined)
                setSubSectorList([])
            }

            if (showAll) {
                // getSector()
                setShowDetail(false)

            }
        }
    }, [$userRole, organizationId, $organizationsSectorsList.data, sectorId, getSubSector, /*getSector*/ showAll])

    useEffect(() => {
        getCategory()
    }, [getCategory])

    useEffect(() => {
        if (sectorIds && sectorIds.length > 0 && subSectorIds === undefined) {
            setShowRange(false)
            setShowDetail(false)
        }

        if (sectorIds && subSectorIds && subSectorIds.length > 1) {
            setShowRange(false)
            setShowDetail(false)
        } else if (sectorIds && subSectorIds && subSectorIds.length === 1) {
            setShowRange(true)
        }
    }, [subSectorIds, sectorIds])

    useEffect(() => {
        getAllowed()
    }, [getAllowed])


    return {
        $changeData,
        sectorLoading,
        sectorList,
        sectorIds,
        subSectorList,
        subSectorIds,
        subSectorLoading,
        categoryIds,
        attributeLoading,
        attributeList,
        attributeIds,
        showRange,
        endValue,
        startValue,
        setStartValue,
        setEndValue,
        dateValue,
        changeDate,
        setCategorySearch,
        showStation,
        stationIds,
        stationList,
        stationLoading,
        allowedEnd,
        allowedStart,
        showDetail,
        setShowDetail,
        changeForm,
        sectorStationIds,
        sectorStationList
    }
}

