import React, {useState} from "react";
import {Row, Col} from "antd";
import {Header} from "../../components/header";
import {MainContainer} from "./page";
import {DashboardContainer} from "./atoms";
import {useHistory} from "react-router-dom";
import ReactGA from "react-ga";
import ModalVideo from "react-modal-video";
import videoUrl from "../../media/video/2021-08-21 12.02.47.mp4";
import 'react-modal-video/css/modal-video.min.css'

export const Dashboard = () => {
    const locale = useHistory();
    ReactGA.pageview(locale.location.pathname + locale.location.search);

    const [isOpen, setOpen] = useState(false)


    return (
        <DashboardContainer>
            <ModalVideo channel='custom' autoplay isOpen={isOpen} url={videoUrl} onClose={() => setOpen(false)} />

            <Row type='flex'>
                <Col span={24}>
                    <Header isOpen={isOpen} setOpen={setOpen}/>
                </Col>
                <MainContainer/>
            </Row>
        </DashboardContainer>
    );
};
