import {useCallback, useEffect, useState} from "react";

export const useUrlParams = (location, param) => {
    const [organizationId, setOrganizationId] = useState(null);
    const [sectorId, setSectorId] = useState(null);
    const [stationId, setStationId] = useState(null);
    const [subSectorId, setSubSectorId] = useState(null);
    const [categoryId, setCategoryId] = useState(false);
    const [attributeId, setAttributeId] = useState(null);
    const [urlParams, setUrlParams] = useState(null);
    const [tabType, setTabType] = useState(null);
    const [sectorPoint, setSectorPoint] = useState(null);
    const [info, setInfo] = useState(false);
    const [stationView, setStationView] = useState(false)


    const generateUrlParams = useCallback(() => {
        const paramsString = location.search;
        const searchParams = new URLSearchParams(paramsString);

        if (searchParams.has('organizationId')) {
            setOrganizationId(searchParams.get('organizationId'))
        } else {
            setOrganizationId(null)
        }

        if (searchParams.has('stationView')) {
            setStationView(searchParams.get('stationView') === 'true')
        } else {
            setStationView(false)
        }

        if (searchParams.has('sectorId')) {
            setSectorId(searchParams.get('sectorId'))
        } else {
            setSectorId(null)
        }

        if (searchParams.has('stationId')) {
            setStationId(searchParams.get('stationId'))
        } else {
            setStationId(null)
        }

        if (searchParams.has('subSectorId')) {
            setSubSectorId(searchParams.get('subSectorId'))
        } else {
            setSubSectorId(null)
        }

        if (searchParams.has('categoryId')) {
            setCategoryId(searchParams.get('categoryId'))
        } else {
            setCategoryId(null)
        }

        if (searchParams.has('attributeId')) {
            setAttributeId(searchParams.get('attributeId'))
        } else {
            setAttributeId(null)
        }

        if (searchParams.has('sectorPoint')) {
            setSectorPoint(searchParams.get('sectorPoint'))
        } else {
            setSectorPoint(null)
        }

        if (searchParams.has('info')) {
            setInfo(true)
        } else {
            setInfo(false)
        }

        if (searchParams.has('tabType')) {
            setTabType(searchParams.get('tabType'))
        } else {
            setTabType(null)
        }

        if (Array.isArray(param) && param.length) {
            param.forEach(item => searchParams.delete(item))
        } else if (typeof param === 'string') {
            searchParams.delete(param)
        }

        setUrlParams(searchParams.toString())
    }, [location.search, param]);


    useEffect(() => {
        generateUrlParams()
    }, [location.search, generateUrlParams]);


    return {
        organizationId,
        sectorId,
        categoryId,
        subSectorId,
        info,
        urlParams,
        setInfo,
        tabType,
        attributeId,
        sectorPoint,
        stationId,
        stationView
    }
};
