import React, {useEffect, useRef, useState} from "react";
import {Row, Col, Modal, Popconfirm} from 'antd';
import {InputSystem} from "./input";
import {
    ModalBody,
    ModalCustom,
    ModalFooter,
    ModalHeader,
    RadioUI,
    Button,
    ButtonBlockFlex,
    ModalFooterWrapper, MiniModalCustom, LargeModalCustom, DropWrapper, CropWrapper, CropModalCustom, DatePickerSystem
} from "../atoms";
import {SelectSystem} from "./select";
import {PlusOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {createEntryAttributeController, getAttributeInfo, getConstraintsInfo} from "../../features/dashboard/api";
import {$isDataPending} from "../../features/dashboard/model";
import {createEntrySectorController} from "../api";
import {withRouter} from "react-router-dom";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import Dropzone from "react-dropzone";
import {
    base64StringtoFile,
    extractImageFileExtensionFromBase64,
    image64toCanvasRef
} from '../../utils/crop-utils'
import ReactCrop from "react-image-crop";
import {AlertTriangleSvg, EditSvg, TrashSvg} from "../../media";
import {CustomTable} from "../../features/dashboard/atoms";
import Moment from "react-moment";
import 'moment/locale/ru';
import {convertKilo, eventGA, openNotificationWithIcon} from "../../helpers";
import {useStore} from "effector-react";
import {timeEvent} from "../../features/dashboard/model/events";
import {USER_ROLES} from "../../constants/user";
import {$userModel} from "../../models/user-model";
import {$organization} from "../../models/organization-model";
import {useTranslation} from "react-i18next";
import {useUrlParams} from "../../hooks/common";
import {deleteAttribute} from "../../api/entry-attr-api";


const {confirm} = Modal;

const ModalAttributeView = (props) => {
    const {t} = useTranslation();
    const {
        statusModal,
        attributeInfo,
        setStatusModal,
        location,
    } = props;
    // const orgSector = useStore($organization);
    const signedUserInfo = useStore($userModel);
    const organization = useStore($organization);
    const {organizationId} = useUrlParams(location);

    //status
    const [loading, setLoading] = useState(false);

    // options
    const [listOptions, setListOptions] = useState([]);

    //data
    const [status, setStatus] = useState(undefined);
    const [fromMeters, setFromMeters] = useState(0);
    const [toMeters, setToMeters] = useState(1000);
    const [listId, setListId] = useState(undefined);
    const [sectorId, setSectorId] = useState(undefined);
    const [sectorPoint, setSectorPoint] = useState(false);
    const [data, setData] = useState([]);
    const [date, setDate] = useState(undefined);
    const [long, setLong] = useState(undefined);
    const [double, setDouble] = useState(undefined);
    const [text, setText] = useState(undefined);


    useEffect(() => {
        if (attributeInfo.type === 'LIST') {
            if (attributeInfo.constraints && attributeInfo.constraints.list) {
                getConstraintsInfo(attributeInfo.constraints.list)
                    .then(response => {
                        if (response.status === 200) {
                            setListOptions(response.data.values)
                        }
                    })
                    .catch(error => {
                        console.error(error.response.data)
                    })
            }
        }
    }, [attributeInfo]);

    useEffect(() => {
        if (location.search) {
            const paramsString = location.search;
            const searchParams = new URLSearchParams(paramsString);
            if (searchParams.has('subSectorId')) {
                setSectorId(searchParams.get('subSectorId'));
            } else {
                setSectorId(searchParams.get('sectorId'));
            }
            if (searchParams.has('sectorPoint')) {
                setSectorPoint(searchParams.get('sectorPoint'));
            }
        }
    }, [location.search]);

    useEffect(() => {
        if (sectorId && sectorPoint && attributeInfo.id) {
            getInfo()
        }

    }, [sectorId, sectorPoint, attributeInfo]);

    const columns = [
        {
            title: t('from'),
            dataIndex: "from",
            key: "from",
            render: fromCount => convertKilo(fromCount)
        },
        {
            title: t('to'),
            dataIndex: "to",
            key: "to",
            render: toCount => convertKilo(toCount) === 0 ? 1000 : convertKilo(toCount)
        },
        {
            title: t('value'),
            dataIndex: "value",
            key: "value",
        },
        {
            title: "",
            key: "action",
            width: '100px',
            render: record => (
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {/*<div onClick={() => {*/}
                    {/*    // setAttributeInfo(record);*/}
                    {/*    // setStatusModal(true);*/}
                    {/*}} style={{textAlign: "center", marginRight: 15, color: '#8F9BB3'}}>*/}
                    {/*    <EditSvg/>*/}
                    {/*</div>*/}
                    {
                        USER_ROLES.USER === signedUserInfo.$userRole &&
                        <Popconfirm
                            title={t('are-you-sure')}
                            okText={t('ok')}
                            cancelText={t('no')}
                            icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                            onConfirm={() => deleteUserAttribute(record)}
                        >
                            <div style={{textAlign: "center", color: '#FF3D71'}}>
                                <TrashSvg/>
                            </div>
                        </Popconfirm>
                    }

                </div>
            )
        }
    ];

    const getInfo = () => {
        const params = {
            ts: null,
            // sectorId: sectorId
        };
        setLoading(true);
        getAttributeInfo(
            attributeInfo.id,
            sectorId,
            Number(sectorPoint) * 1000,
            Number(sectorPoint) * 1000 + 1000,
            params)
            .then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    const data = response.data.map((item, index) => ({
                        key: index,
                        id: item.id,
                        from: item.fromMeter,
                        to: item.toMeter,
                        value: item.entry.type === 'DATE' ?
                            <Moment locale='ru' format="DD/MM/YYYY"
                                    date={item.entry.value}/> : item.entry.name ? item.entry.name : item.entry.value
                    }));
                    setData(data);
                    // console.log('entryResponse', response.data, data)
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('entryError', error.response)
            })
    };

    const createEntryAttribute = () => {
        const data = {
            color: status,
            fromMeter: Number(sectorPoint) * 1000 + Number(fromMeters),
            toMeter: Number(sectorPoint) * 1000 + Number(toMeters),
            // username: 'Chipxack'
        };
        // if (attributeInfo.type === 'LIST'){
        //     data.entry = listOptions.filter(item => item.value === listId)[0]
        // }
        switch (attributeInfo.type) {
            case 'LIST':
                data.entry = {
                    id: listOptions.filter(item => item.value === listId)[0].value,
                    value: listOptions.filter(item => item.value === listId)[0].name,
                    type: 'LIST'
                };
                break;
            case 'DATE':
                data.entry = {
                    value: date,
                    type: 'DATE'
                };
                break;
            case 'LONG':
                data.entry = {
                    value: long,
                    type: 'LONG'
                };
                break;
            case 'DOUBLE':
                data.entry = {
                    value: double,
                    type: 'DOUBLE'
                };
                break;
            case 'TEXT':
                data.entry = {
                    value: text,
                    type: 'TEXT'
                };
                break;
            default:
                return null
        }
        if (sectorId) {
            createEntryAttributeController(attributeInfo.id, sectorId, data)
                .then(response => {
                    if (response.status === 200) {
                        if (organization.$organizationsList && organization.$organizationsList.data && organization.$organizationsList.data.length > 0) {
                            const label = organization.$organizationsList.data.filter(item => item.id === Number(organizationId))[0].label;
                            eventGA(
                                'Attribute',
                                'create_attribute',
                                label,
                            )
                        }
                        getInfo();
                        // setStatusModal(statusModal === 'ping' ? 'pong' : 'ping')
                    }
                })
                .catch(error => {
                    console.error(error.response)
                })
        }

    };

    const deleteUserAttribute = (data) => {
        deleteAttribute(data.id)
            .then(response => {
                if (response.status === 200) {
                    openNotificationWithIcon('success', t('success'), t('attribute-delete'))
                    eventGA(
                        'Attribute',
                        'delete_attribute',
                        attributeInfo.name && attributeInfo.name.props && attributeInfo.name.props.title,
                    )
                    getInfo();
                }
            })
            .catch(error => {
                if (error.response.status === 204) {
                    openNotificationWithIcon('error', t('error'), t('attribute-not-found'))
                    getInfo();
                }
            })
    }

    const replaceDouble = (e) => {
        const data = e.replace(/\D/ig, function () {
            let dotCount = 0;
            return function ($0) {
                if ($0 === attributeInfo.constraints.double && !dotCount) {
                    dotCount += 1;
                    return $0;
                }
                return '';
            }
        }());
        setDouble(data)
        // setDouble(data);
    };

    useEffect(() => {
        if (organization.$sectorLength.result && organization.$sectorLength.result.allowedStart && organization.$sectorLength.result.start === Number(sectorPoint)) {
            setFromMeters(Number(convertKilo(organization.$sectorLength.result.allowedStart)))
        } else {
            setFromMeters(0);
        }
    }, [sectorPoint, organization.$sectorLength.result]);

    const changeFromMeters = () => {
        if (organization.$sectorLength.result && organization.$sectorLength.result.allowedStart && organization.$sectorLength.result.start === Number(sectorPoint) && Number(fromMeters) < Number(convertKilo(organization.$sectorLength.result.allowedStart))) {
            setFromMeters(Number(convertKilo(organization.$sectorLength.result.allowedStart)))
        } else {
            setFromMeters(Number(fromMeters));
        }
    };

    // console.log(attributeInfo)

    return (
        <ModalCustom
            title={attributeInfo.name}
            visible={statusModal}
            headStyle={{display: 'none'}}
            footer={null}
            onCancel={() => {
                setStatusModal(false)
                timeEvent(Date.now());
            }}
        >
            <ModalHeader>
                <h1>
                    {attributeInfo.name}
                </h1>
                <div>
                    <span>{t('state')}</span>
                    <RadioUI.Group onChange={(e) => setStatus(e.target.value)}>
                        <RadioUI status='success' value='rgb(0, 224, 150)'/>
                        <RadioUI status='warning' value='rgba(255, 170, 0)'/>
                        <RadioUI status='error' value='rgba(255, 17, 110'/>
                    </RadioUI.Group>
                </div>

            </ModalHeader>
            <ModalBody>
                <CustomTable
                    style={{margin: '30px 0'}}
                    className='second-color popap'
                    // showHeader={false}
                    title={false}
                    pagination={false}
                    dataSource={data}
                    columns={columns}
                />
            </ModalBody>
            {
                statusModal && signedUserInfo.$userRole === USER_ROLES.EMPLOYEE &&
                <ModalFooter>
                    <Row type='flex' gutter={10}>
                        <Col span={7}>
                            <InputSystem
                                onBlur={(e) => changeFromMeters(e)}
                                disabled={loading}
                                value={fromMeters}
                                change={(e) => setFromMeters(e)}
                                label={t('from')}
                                placeholder={t('in-meters')}
                            />
                        </Col>
                        <Col span={7}>
                            <InputSystem
                                disabled={loading}
                                value={toMeters}
                                change={setToMeters}
                                label={t('to')}
                                placeholder={t('in-meters')}
                            />
                        </Col>

                        <Col span={7}>
                            {
                                attributeInfo.type === 'LIST' ?
                                    <SelectSystem
                                        disabled={loading}
                                        options={listOptions}
                                        change={setListId}
                                        label={t('value')}
                                        placeholder={t('select')}
                                    /> :
                                    attributeInfo.type === 'DATE' ?
                                        <InputSystem
                                            disabled={loading}
                                            type='date'
                                            label={t('value')}
                                            placeholder={t('select')}
                                            change={setDate}
                                        /> :
                                        attributeInfo.type === 'LONG' ?
                                            <InputSystem
                                                disabled={loading}
                                                value={long}
                                                type='number'
                                                label={t('value')}
                                                placeholder={t('select')}
                                                change={setLong}
                                                minLength={0}
                                            /> :
                                            attributeInfo.type === 'DOUBLE' ?
                                                <InputSystem
                                                    disabled={loading}
                                                    value={double}
                                                    type='text'
                                                    label={t('value')}
                                                    placeholder=''
                                                    change={replaceDouble}
                                                /> :
                                                attributeInfo.type === 'TEXT' ?
                                                    <InputSystem
                                                        disabled={loading}
                                                        value={text}
                                                        type='text'
                                                        label={t('value')}
                                                        placeholder=''
                                                        change={setText}
                                                    />
                                                    : null
                            }
                        </Col>
                        <ButtonBlockFlex>
                            <Button
                                loading={loading}
                                disabled={
                                    !status
                                    || fromMeters === undefined
                                    || !toMeters
                                }
                                onClick={() => createEntryAttribute()}
                                noChildren
                                style={{height: 48, width: 48}}
                            >
                                <PlusOutlined/>
                            </Button>
                        </ButtonBlockFlex>
                    </Row>
                </ModalFooter>
            }

        </ModalCustom>
    )
};


const ModalSectorsView = (props) => {
    const {t} = useTranslation();
    const {
        statusModal,
        setStatusModal,
        location
    } = props;
    const signedUserInfo = useStore($userModel);


    const isDataPending = useStore($isDataPending);

    //data
    const [status, setStatus] = useState(undefined);
    const [fromMeters, setFromMeters] = useState(undefined);
    const [toMeters, setToMeters] = useState(undefined);
    const [velocityFrom, setVelocityFrom] = useState(undefined);
    const [velocityTo, setVelocityTo] = useState(undefined);
    const [tonnage, setTonnage] = useState(undefined);


    // console.log(attributeInfo)
    // const paramsString = location.search;
    // const searchParams = new URLSearchParams(paramsString);
    const paramsString = location.search;
    const searchParams = new URLSearchParams(paramsString);
    // console.log(searchParams.get('subSectorId'))


    useEffect(() => {
        setFromMeters(statusModal);
        setToMeters(statusModal);
    }, [statusModal]);

    useEffect(() => {
        if (searchParams.has('subSectorId') === isDataPending.$sectorInfo.data.startPoint) {
            if (fromMeters < isDataPending.$sectorInfo.data.startPoint) {
                setFromMeters()
            }
        }
    }, [fromMeters]);

    const createEntryAttribute = () => {
        // console.log('button')
        const paramsString = location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.has('subSectorId')) {
            // setParamOrganizationId(searchParams.get('organizationId'));
            const data = {
                color: status,
                fromMeter: Number(fromMeters) * 1000,
                toMeter: Number(toMeters) * 1000 + 999,
                tonnage: tonnage,
                velocityFrom: velocityFrom,
                velocityTo: velocityTo
            };
            // console.log(data)
            createEntrySectorController(searchParams.get('subSectorId'), data)
                .then(response => {
                    if (response.status === 200) {
                        console.log(response.data);
                    }
                })
                .catch(error => {
                    console.error(error.response)
                })
        }
    };

    return (
        <ModalCustom
            title={t('sector')}
            visible={statusModal}
            headStyle={{display: 'none'}}
            footer={null}
            onCancel={() => setStatusModal(false)}
        >
            <ModalHeader>
                <h1>
                    {t('sector')}
                </h1>
                <div>
                    <span>Состояние</span>
                    <RadioUI.Group onChange={(e) => setStatus(e.target.value)}>
                        <RadioUI status='success' value='rgb(0, 224, 150)'/>
                        <RadioUI status='warning' value='rgba(255, 170, 0)'/>
                        <RadioUI status='error' value='rgba(255, 17, 110'/>
                    </RadioUI.Group>
                </div>
            </ModalHeader>
            <ModalBody>

            </ModalBody>
            {
                signedUserInfo.$userRole === USER_ROLES.EMPLOYEE &&
                <ModalFooter>
                    <Row type='flex' gutter={10}>
                        <Col span={5}>
                            <InputSystem
                                disabled
                                value={fromMeters}
                                change={setFromMeters}
                                label={t('from')}
                                placeholder={t('in-meters')}
                            />
                        </Col>
                        <Col span={5}>
                            <InputSystem
                                value={toMeters}
                                change={setToMeters}
                                label={t('to')}
                                placeholder={t('in-meters')}
                            />
                        </Col>
                        <Col span={7}>
                            <Row type='flex' gutter={10}>
                                <Col span={12}>
                                    <InputSystem
                                        value={velocityFrom}
                                        change={setVelocityFrom}
                                        label={t('km-h')}
                                        placeholder={t('km-h')}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InputSystem
                                        value={velocityTo}
                                        change={setVelocityTo}
                                        label=' '
                                        placeholder={t('km-h')}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={5}>
                            <InputSystem
                                value={tonnage}
                                change={setTonnage}
                                label={t('tonnage')}
                                placeholder={t('in-meters')}
                            />
                        </Col>
                        {/*<Col span={7}>*/}
                        {/*    <SelectSystem*/}
                        {/*        options={listOptions}*/}
                        {/*        change={setListId}*/}
                        {/*        label='Значение'*/}
                        {/*        placeholder='Выберите'*/}
                        {/*    />*/}
                        {/*</Col>*/}
                        <ButtonBlockFlex>
                            <Button
                                // disabled={
                                //     !status
                                //     || !fromMeters
                                //     || !toMeters
                                // }
                                onClick={() => createEntryAttribute()}
                                noChildren
                                style={{height: 56, width: 56}}
                            >
                                <PlusOutlined/>
                            </Button>
                        </ButtonBlockFlex>
                    </Row>
                </ModalFooter>
            }

        </ModalCustom>
    )
};

export const ModalTariff = (props) => {
    const {t} = useTranslation();
    const {
        statusModal,
        setStatusModal,
        title,

    } = props;

    return (
        <ModalCustom
            title={title}
            visible={statusModal}
            headStyle={{display: 'none'}}
            footer={null}
            onCancel={() => setStatusModal(false)}
        >
            <ModalHeader>
                <h1>{t('change-map')}</h1>
            </ModalHeader>
            <ModalBody>

            </ModalBody>
            <ModalFooter>
                <ModalFooterWrapper>
                    <Button
                        type='link'
                        onClick={() => setStatusModal(false)}
                    >
                        {t('cancel')}
                    </Button>
                    <Button>
                        {t('done')}
                    </Button>
                </ModalFooterWrapper>
            </ModalFooter>
        </ModalCustom>
    )
};

export const UserProfileModal = (props) => {
    const {
        component,
        statusModal,
        setStatusModal,
        title,
        width,
        footer
    } = props;

    return (
        <MiniModalCustom
            title={title}
            visible={statusModal}
            headStyle={{display: 'none'}}
            footer={null}
            onCancel={() => setStatusModal(false)}
            width={width}
        >
            <ModalBody style={{minHeight: 'unset'}}>
                {component}
            </ModalBody>
            {/*{*/}
            {/*    footer && (*/}
            {/*        <ModalFooter>*/}
            {/*            <ModalFooterWrapper>*/}
            {/*                <Button*/}
            {/*                    type='link'*/}
            {/*                    onClick={() => setStatusModal(false)}*/}
            {/*                >*/}
            {/*                    Отменить*/}
            {/*                </Button>*/}
            {/*                <Button>*/}
            {/*                    Применить*/}
            {/*                </Button>*/}
            {/*            </ModalFooterWrapper>*/}
            {/*        </ModalFooter>*/}
            {/*    )*/}
            {/*}*/}

        </MiniModalCustom>
    )
};

export const UserListModal = (props) => {
    const {
        statusModal,
        setStatusModal,
        title,
        component
    } = props


    return (
        <LargeModalCustom
            title={title}
            visible={statusModal}
            headStyle={{display: 'none'}}
            footer={null}
            onCancel={() => setStatusModal(false)}
        >
            <ModalBody style={{minHeight: 'unset'}}>
                {component}
            </ModalBody>
        </LargeModalCustom>
    )
}

export const DeleteConfirm = (action) => {
    const {t} = useTranslation();
    confirm({
        title: t('want-to-delete'),
        icon: <ExclamationCircleOutlined/>,
        okText: t('yes'),
        okType: 'danger',
        cancelText: t('no'),
        onOk() {
            action()
        },
        onCancel() {
        },
    });
}

export const ImageUploadModal = (props) => {
    const {t} = useTranslation();


    const {statusModal, setStatusModal, title, setFileValue} = props;
    const [imgSrc, setImgSrc] = useState(null)
    const [imgSrcExt, setImgSrcExt] = useState(null)
    const [crop, setCrop] = useState({unit: '%', aspect: 1 / 1, width: 50})
    const [error, setError] = useState(null)

    const imagePreviewCanvasRef = useRef(null)

    const imageMaxSize = 500000 // bytes
    const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
    const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
        return item.trim()
    });

    const verifyFile = (files) => {
        if (files && files.length > 0) {
            const currentFile = files[0].file !== undefined ? files[0].file : files[0];
            const currentFileType = currentFile.type;
            const currentFileSize = currentFile.size;

            // console.log(currentFile);
            if (currentFileSize > imageMaxSize) {
                // alert("This file is not allowed. " + currentFileSize + " bytes is too large")
                setError(`${t('max-size-image')} 300x300`);
                return false

            }
            if (!acceptedFileTypesArray.includes(currentFileType)) {
                setError(t('image-not-allowed'));
                return false
            }
            return true
        }
    };


    const handleOnDrop = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            verifyFile(rejectedFiles)
        }

        if (files && files.length > 0) {
            const isVerified = verifyFile(files)
            if (isVerified) {
                const currentFile = files[0]
                const myFileItemReader = new FileReader()
                myFileItemReader.onload = (e) => {
                    const myResult = e.target.result
                    setImgSrc(myResult)
                    setImgSrcExt(extractImageFileExtensionFromBase64(myResult))
                    return myResult
                }

                myFileItemReader.readAsDataURL(currentFile)
            }
        }

    }


    const handleOnCropComplete = (crop, pixelCrop) => {
        const canvasRef = imagePreviewCanvasRef.current
        image64toCanvasRef(canvasRef, imgSrc, crop)

    }

    const handleOnCropChange = (crop) => {
        setCrop(crop)
    }

    const createCroppedImage = (e) => {
        e.preventDefault()
        if (imgSrc) {
            const canvasRef = imagePreviewCanvasRef.current
            const imageData64 = canvasRef.toDataURL('image/' + imgSrcExt)
            const myFilename = "previewFile." + imgSrcExt
            const myNewCroppedFile = base64StringtoFile(imageData64, myFilename)

            setFileValue(myNewCroppedFile)
            setStatusModal(false)
            handleClearToDefault()
            // downloadBase64File(imageData64, myFilename)
        }
    }

    const handleClearToDefault = () => {
        setImgSrc(null)
        setImgSrcExt(null)
        setCrop({unit: '%', aspect: 1 / 1, width: 50})
        setStatusModal(false)
        setError(null)
    }

    return (
        <CropModalCustom
            title={title}
            visible={statusModal}
            headStyle={{display: 'none'}}
            footer={null}
            onCancel={() => setStatusModal(false)}
        >
            <ModalHeader>
                <h1>{title}</h1>
            </ModalHeader>
            <ModalBody>
                {imgSrc !== null
                    ?
                    <CropWrapper>
                        <ReactCrop
                            src={imgSrc}
                            crop={crop}
                            onComplete={handleOnCropComplete}
                            onChange={handleOnCropChange}
                        />
                        <canvas style={{display: 'none'}} ref={imagePreviewCanvasRef}>
                        </canvas>
                    </CropWrapper>
                    :
                    <Dropzone onDrop={handleOnDrop} accept={acceptedFileTypes} multiple={false}
                              maxSize={imageMaxSize}>
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <DropWrapper {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div>Перетащите файл сюда</div>
                                    <span>или</span>
                                    <Button style={{marginBottom: 10}}>Выберите локальный файл</Button>
                                    {error !== null &&
                                    <strong>
                                        <AlertTriangleSvg/>
                                        {error}
                                    </strong>
                                    }
                                </DropWrapper>
                            </section>
                        )}
                    </Dropzone>

                }

            </ModalBody>

            <ModalFooter>
                <ModalFooterWrapper>
                    <Button
                        type='link'
                        onClick={handleClearToDefault}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        onClick={createCroppedImage}
                    >
                        {t('done')}
                    </Button>

                </ModalFooterWrapper>
            </ModalFooter>

        </CropModalCustom>
    )
};

export const ModalAttribute = withRouter(ModalAttributeView);
export const ModalSectors = withRouter(ModalSectorsView);
