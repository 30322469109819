import React from 'react';
import {$isDataPending} from '../../../models/categories-attr-model';
import {useStore} from "effector-react";
import {Menu} from "antd";
import {NavLink, useLocation} from "react-router-dom";
import {NavLinksBlock, TabCustom} from "../atoms";
import {useUrlParams} from "../../../hooks/common";


export const CategoriesList = () => {
    const location = useLocation();
    const {categoryId, urlParams} = useUrlParams(location, 'categoryId');
    const isDataPending = useStore($isDataPending);

    return (
        <TabCustom>
            <Menu
                style={{width: '100%'}}
                mode="inline"
            >
                <NavLinksBlock>
                    {
                        isDataPending.$dashboardCategoriesList.data.data &&
                        isDataPending.$dashboardCategoriesList.data.data.map(item =>
                            <NavLink
                                activeClassName="active"
                                to={{
                                    pathname: '/',
                                    search: `categoryId=${item.id}${urlParams ? '&' + urlParams : ''}`,
                                }}
                                isActive={() => {return Number(categoryId) === Number(item.id)}}
                                key={item.id}
                            >
                                {item.name}
                            </NavLink>
                        )
                    }
                </NavLinksBlock>

            </Menu>
        </TabCustom>

    )
};