import styled from 'styled-components';
import {Button} from "antd";



export const CarouselButton = styled(Button)`
  &.ant{
    &-btn{
      transition: 0.3s;
      background: ${props => (props.isactive === 'true' ? "#3366FF" : "#fff")};
      border-radius: 8px;
      height: 44px;
      padding: 0 20px !important;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 44px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 !important;
      color: ${props => (props.isactive === 'true' ? "#FFFFFF" : "#222B45")};
      span{
        background: transparent;
        padding: 0;
        color: inherit;
      }
      &:hover, &.active {
        color: #ffffff;
        background: #3366ff;
      }
      .anticon{
        background: transparent;
        color: ${props => (props.isactive === 'true' ? "#FFFFFF" : "#222B45")};
        padding: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        font-size: 26px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
`;


export const SyncButton = styled.button`
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #222B45;
  &:hover, &:focus{
    cursor: pointer;
  }
  &:disabled{
    color: #8F9BB3;
  }
`