import {createEvent} from "effector";
import {
    fetchOrganizationList,
    fetchOrganizationSectorsList,
    fetchSectorInfo,
    fetchContextInfo,
    fetchOrgList,
    fetchMultipleSubSector,
    fetchOrganizationListUser
} from "./effects";


export const organizationListMount = createEvent();
export const organizationListMountUser = createEvent();
export const getOrganizationSectorsList = createEvent();
export const mountSubSectorsToParent = createEvent();
export const contextInfo = createEvent();
export const getSectorsFromOrganization = createEvent();
export const userOrganizations = createEvent();
export const createSectorLength = createEvent();
export const previousEvent = createEvent();
export const nextEvent = createEvent();
export const orgListMount = createEvent();
export const multipleSubSectorMount = createEvent();
export const multipleSubSectoFromPermission = createEvent();




organizationListMount.watch(fetchOrganizationList);
organizationListMountUser.watch(fetchOrganizationListUser);
getOrganizationSectorsList.watch(fetchOrganizationSectorsList);
mountSubSectorsToParent.watch(fetchSectorInfo);
multipleSubSectorMount.watch(fetchMultipleSubSector);
contextInfo.watch(fetchContextInfo);
orgListMount.watch(fetchOrgList);
