import {combine, createStore} from "effector";
import {fetchOrgUserList} from "./effects";
import {lengthFormatter} from "../../helpers";

const $orgUserList = createStore({loading: false, data: [], error: false})
    .on(fetchOrgUserList.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchOrgUserList.finally, (state, response) => {
        if (response.error) {
            return {
                ...state,
                error: response.error.response
            }
        } else {
            return {
                ...state,
                error: false,
                data: response.result.data.map(item =>  ({
                    key: item.id,
                    image: item.user.image,
                    online: 'В сети',
                    name: `${item.user.name} ${item.user.sname}`,
                    role: item.user.position ? item.user.position.title : '',
                    phone: item.user.phone,
                    sector:  item.permissions.length > 0 ? item.permissions.map(item =>  item.sector ? {id: item.sector.id, name: item.sector.name} : '') : null,
                    subSector: item.permissions.length > 0 ? item.permissions.map(item =>  item.subSector ? item.subSector.name : '') : null,
                    division: item.permissions.length > 0 ? item.permissions.map(item =>  {
                        return item.allowedStart && item.allowedEnd ?  `${lengthFormatter(item.allowedStart)} - ${lengthFormatter(item.allowedEnd)}` : ''
                    }): null
                })),
            }
        }
    })

export const $infoModel = combine({
    $orgUserList
})

