import {Button, DatePicker} from "antd";
import styled from "styled-components";

export const NoBorderButton = styled(Button)`
  border: none !important;
  box-shadow: none !important;
  background: none !important;
  width: 100%;
  span{
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #8F9BB3;
  }
 
`;

export const NoBorderDatePicker = styled(DatePicker)`
  &.ant{
    &-picker{
      border: none;
      box-shadow: none;
      background: none;
      opacity: 1;
      padding: 6px 0 6px 11px;
    }
  }
  .ant{
    &-picker{
      &-input{
        & > input{
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: #8F9BB3;
          width: 80px;
        }
      }
      &-suffix{
        display: none;
      }
      &-clear{
        display: none;
      }
    }
  }
`;
