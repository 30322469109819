import { createEffect } from "effector";
import { getOrganizationList, getOrganizationSectorList, getMultipleSubSectorList } from "../../api/organization-api";
import { getSectorInfo } from "../../api/sector-api";
import { getContextInfo } from "../../api/context-api";


export const fetchOrganizationList = createEffect({
    handler: getOrganizationList
});

export const fetchOrganizationListUser = createEffect({
    handler: getOrganizationList
});

export const fetchOrganizationSectorsList = createEffect({
    handler: getOrganizationSectorList
});

export const fetchSectorInfo = createEffect({
    handler: getSectorInfo
});

export const fetchMultipleSubSector = createEffect({
    handler: getMultipleSubSectorList
});

export const fetchContextInfo = createEffect({
    handler: getContextInfo
});

export const fetchOrgList = createEffect({
    handler: getOrganizationList
});