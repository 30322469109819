import {createEffect} from "effector";
import {
    getContextInfo,
    getOrganizationList,
    getOrganizationInfo,
    getOrganizationSectorList,
    getSubSectorList,
    getSubSectorInfo,
    getSectorsList,
    getAttributeCategory,
    getEntrySector,
    getLogList,
    getSectorInfo,
    getCategoriesList,
    getDashboardSectorList,
    getSectorList,
    getUserLIst,
    getOrganizationUserList,
} from "../api";

export const fetchSectorsList = createEffect({
    handler: getSectorsList
});
export const fetchDashboardSectorsList = createEffect({
    handler: getDashboardSectorList
});
export const fetchSubSectorsList = createEffect({
    handler: getSubSectorInfo
});

export const fetchContextInfo = createEffect({
    handler: getContextInfo
});

export const fetchOrganizationList = createEffect({
    handler: getOrganizationList
});

export const fetchOrganizationInfo = createEffect({
    handler: getOrganizationInfo
});

export const fetchOrganizationSectorList = createEffect({
    handler: getOrganizationSectorList
});

export const fetchSubSectorList = createEffect({
    handler: getSubSectorList
});

export const fetchSectorInfo = createEffect({
    handler: getSectorInfo
});

export const fetchCategoriesList = createEffect({
    handler: getCategoriesList
});

///////////////////////////////////////////////////////////////

export const fetchAttributeList = createEffect({
    handler: getAttributeCategory
});

export const fetchEntrySector = createEffect({
    handler: getEntrySector
});

export const fetchLogList = createEffect({
    handler: getLogList
});
export const fetchAddLogList = createEffect({
    handler: getLogList

});

export const fetchSectorList = createEffect({
    handler: getSectorList
});

export const fetchUserList = createEffect({
    handler: getUserLIst
});

export const fetchOrganizationUserList = createEffect({
    handler: getOrganizationUserList
});
