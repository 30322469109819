import React, {Fragment, useEffect, useCallback, useState} from "react";
import {Link} from "react-router-dom";
import {HeaderCenter, HeaderLeft, HeaderRight, HeaderSection, Overlay,} from "./atoms";
import {LogoAisupSvg, SearchSvg} from "../../media";
import {UserProfile} from "./organisms";
import {Notification} from "./organisms";
import {menuVisibleStatus, $appSettings} from "../../models/app-model";
import {useStore} from "effector-react";
import {useHistory} from "react-router-dom";
import {getSectorList} from "../../api/sector-api";
import {clearSelected} from "../../models/report-model";
import {InputSystem} from "../../ui/molecules";
import {PlayCircleFilled} from "@ant-design/icons";


export const Header = ({setOpen}) => {
    const {$menuVisible} = useStore($appSettings);
    const {location} = useHistory();
    const [search, setSearch] = useState(undefined)


    const windowResize = () => {
        if (window.innerWidth <= 1280) {
            menuVisibleStatus(false)
        } else {
            menuVisibleStatus(true)
        }
    };

    const bodyOverflow = useCallback(() => {
        const body = document.body;
        if (window.innerWidth <= 1200) {
            if ($menuVisible) {
                body.style.overflow = 'hidden'
            } else {
                body.style.overflow = 'visible'
            }
        } else {
            body.style.overflow = 'visible'
        }
    }, [$menuVisible])

    useEffect(() => {
        bodyOverflow()
    }, [bodyOverflow]);

    useEffect(() => {
        window.addEventListener('resize', (e) => {

            windowResize()
        })

    }, [$menuVisible]);


    useEffect(() => {
        if (window.innerWidth <= 1280) {
            windowResize()
        }
    }, [location]);

    useEffect(() => {
        if (search){
            const params = {
                "search.value": search,
                "search.regex": true
            }
            getSectorList(params)
                .then(response => {
                    if (response.status === 200){
                        console.log(response.data)
                    }
                })
                .catch(error => {
                    console.error(error.response)
                })
        }
    }, [search])


    return (
        <Fragment>
            <Overlay visible={$menuVisible} onClick={() => menuVisibleStatus(false)}/>
            <HeaderSection>
                <HeaderLeft>
                    {/*<MainButton onClick={() => menuVisibleStatus(!$menuVisible)}>*/}
                    {/*    <MainButtonSvg/>*/}
                    {/*</MainButton>*/}
                    <Link to="/" onClick={() => clearSelected()}>
                        {/*<BlueLogoSvg/>*/}
                        <LogoAisupSvg/>
                    </Link>
                </HeaderLeft>
                <HeaderCenter>
                    {/*<InputSystem change={setSearch} value={search} icon={<SearchSvg/>} placeholder="Поиск..."/>*/}
                </HeaderCenter>
                <HeaderRight>
                    <PlayCircleFilled style={{color: '#8F9BB3', width: 24, height: 24}} onClick={() => setOpen(true)}/>
                    <Notification/>
                    <UserProfile/>
                </HeaderRight>
            </HeaderSection>
        </Fragment>
    );
};
