import {httpGet} from "../api";

export const getCategoriesList = (params) => httpGet({
    url: '/category/list', params
});

export const getCategoryInfo = (id) => httpGet({
    url: `/category/${id}`
})

export const getAttributeInfo = (id) => httpGet({
    url: `/attribute/${id}`
})

export const getAttributeCategory = (id) => httpGet({
    url: `/attribute/list/${id}`
});