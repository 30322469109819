import React, {useEffect, useCallback, useState, Fragment} from 'react'
import {ReportInfiniteScroll, ReportListWrapper, ReportModalTitle, ReportModalWrapper} from "../atoms";
import {Button, ModalFooterWrapper} from "../../../ui/atoms";
import {useStore} from "effector-react";
import {$report, clearCommonReport, subSectorMount} from "../../../models/report-model";
import {ReportListsItem} from "./report-list-item";
import {ReportListTab} from "./report-list-tab";
import {getEnd, getStart, isAdmin} from "../../../hooks/info/helper";
import {$userModel} from "../../../models/user-model";
import {useTranslation} from "react-i18next";

const initialParams = {
    length: 30,
    start: 0
}

export const SubSectorModal = ({title, handleAccept, setStatusModal}) => {
    const {$selectedIds, $reportSubSectorList} = useStore($report)
    const [selected, setSelected] = useState(null)
    const [showIndex, setShowIndex] = useState(0)
    const {$userRole} = useStore($userModel)
    const {t} = useTranslation()

    const {data, params} = $reportSubSectorList

    const getSubSectorList = useCallback((params) => {
        subSectorMount(params)
    }, [])

    useEffect(() => {
        let timeout = null

        timeout = setTimeout(() => {
            if (Object.values($selectedIds.sub_sector).length > 0) {
                setSelected($selectedIds.sub_sector)
            } else {
                setSelected(null)
            }
        }, 300)

        return () => {
            clearTimeout(timeout)
            timeout = null
        }
    }, [$selectedIds.sub_sector])

    const handleClick = () => {
        const data = {...selected}
        let isChanged = false
        const arr = Object.keys(data)

        for (let i = 0; i < arr.length; i++) {
            if ($selectedIds.sub_sector[arr[i]] === undefined) {
                isChanged = true
                break
            }
        }

        if (isChanged) {
            clearCommonReport()
        }

        const params = {
            ...$selectedIds,
            station_sector: {},
            startValue: '',
            endValue: '',
            showDetail: arr.length > 1 ? false : $selectedIds.showDetail,
            sub_sector: {...data}
        }

        if (Object.values(data).length === 1) {
            const obj = Object.values(data).find((item, idx) => idx === 0)
            const startValue = getStart(obj)
            const endValue = getEnd(obj)
            params['startValue'] = startValue
            params['endValue'] = endValue
        }

        handleAccept(params)
    }


    const load = (page, id) => {
        if (isAdmin($userRole)) {
            const params = {
                id,
                params: {
                    ...initialParams,
                    start: 30 * page,
                }
            }
            getSubSectorList(params)
        }
    }

    const parents = Object.values($selectedIds.sector)

    return (
        <ReportModalWrapper>
            <ReportModalTitle>
                {title}
            </ReportModalTitle>
            {
                parents.length > 0
                && (
                    <>
                        <ReportListTab
                            data={parents}
                            setShowIndex={setShowIndex}
                            showIndex={showIndex}
                        />
                        <ReportListWrapper>
                            {
                                parents.map((parent, idx) => (
                                    <Fragment key={`${idx + 1}`}>
                                        {
                                            showIndex === idx
                                            && (
                                                <ReportInfiniteScroll
                                                    pageStart={0}
                                                    loadMore={(page) => load(page, parent.id)}
                                                    hasMore={params[parent.id] && params[parent.id].next}
                                                    loader={<div className="loader" key={0}>Loading ...</div>}
                                                    useWindow={false}
                                                    threshold={400}
                                                >
                                                    {
                                                        data[parent.id] && data[parent.id].map(item => (
                                                            <ReportListsItem
                                                                multipleSelect
                                                                key={item.id}
                                                                item={item}
                                                                selected={selected}
                                                                setSelected={setSelected}
                                                            />
                                                        ))
                                                    }
                                                </ReportInfiniteScroll>
                                            )
                                        }

                                    </Fragment>
                                ))
                            }
                        </ReportListWrapper>
                    </>
                )
            }
            <ModalFooterWrapper>
                <Button
                    type='link'
                    onClick={() => setStatusModal(false)}
                >
                    {t('cancel')}
                </Button>
                <Button onClick={handleClick}>
                    {t('apply')}
                </Button>
            </ModalFooterWrapper>
        </ReportModalWrapper>
    )
}