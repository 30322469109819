import React from 'react'
import { useHistory } from 'react-router-dom'
import {useUrlParams} from '../../../hooks/common/'
import { AllReportForm } from '../moleculas'
import {OrganizationReportForm} from "../moleculas";

export const ReportForm = () => {
    const {location} = useHistory()
    const {organizationId} = useUrlParams(location)
    
    if(organizationId) {
        return <OrganizationReportForm />
    }

    return <AllReportForm />
}