import React from 'react'
import {LoadingContainer, Container} from "./style";
import {Spinner} from "../../ui/molecules";

export const SectionWithSpinner = ({loading, children}) => {
  return (
    <Container>
      {
        children
      }
      {
        loading
        && (
          <LoadingContainer>
            <Spinner size="small"/>
          </LoadingContainer>
        )
      }
    </Container>
  )
}