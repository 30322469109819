import React, {useCallback, useEffect} from "react";
import {useUrlParams} from "../../hooks/common";
import {useStore} from "effector-react";
import {$organization, getOrganizationSectorsList, getSectorsFromOrganization} from "../../models/organization-model";
import {$userModel} from "../../models/user-model";
import {USER_ROLES} from "../../constants/user";
import {useHistory} from "react-router-dom";

export const SectorsHooks = () => {
    const {location} = useHistory();
    const organization = useStore($organization);
    const {organizationId} = useUrlParams(location);
    const signedUserInfo = useStore($userModel);


    const getSectors = useCallback((data) => {
        if (organizationId) {
            if (signedUserInfo.$userRole === USER_ROLES.ADMIN || signedUserInfo.$userRole === USER_ROLES.SUPER_ADMIN || signedUserInfo.$userRole === USER_ROLES.BOSS) {
                getOrganizationSectorsList(organizationId)
            } else if (signedUserInfo.$userRole === USER_ROLES.USER) {
                getOrganizationSectorsList(organizationId)
            } else if (signedUserInfo.$userRole !== null) {
                data.result.filter(item => {
                    if (item.organization.id === Number(organizationId)) {
                        getSectorsFromOrganization(item.permissions);
                    }
                });
            }
        }
    }, [organizationId, signedUserInfo.$userRole]);

    useEffect(() => {
        if (organization.$organizationsList && organization.$organizationsList.data.length > 0) {
            getSectors(organization.$organizationsList);
        }
    }, [getSectors, organization.$organizationsList.data]);


    // useEffect(() => {
    //     if (subSectorId && organization.$sectorLength.data && organization.$sectorLength.data.length > 0) {
    //         console.log('length', organization.$sectorLength.data)
    //         const fromLength = organization.$sectorLength.data[0].name;
    //         const toLength = organization.$sectorLength.data[organization.$sectorLength.data.length - 1].name;
    //         if (fromLength && toLength){
    //             const params = {
    //                 ts: Date.now(),
    //                 from: Number(organization.$sectorLength.data[0].name) * 1000,
    //                 to: Number(organization.$sectorLength.data[organization.$sectorLength.data.length - 2].name) * 1000,
    //                 sectorId: subSectorId
    //             };
    //             entrySection(params);
    //         }
    //
    //     }
    // }, [subSectorId, organization.$sectorLength.data]);

    return null
};