import {combine, createStore} from "effector";
import {
    fetchAllOrgInfo,
    fetchAllSummary,
    fetchAttribute,
    fetchCategory,
    fetchDetailSummary,
    fetchOrganizationFullInfo,
    fetchOrganizationInfo,
    fetchOrganizationSector,
    fetchStation,
    fetchStationSubSector,
    fetchSubSector,
    fetchSummary
} from "./effect";
import {convertTo} from "../../helpers";
import {
    changeCommonReportSlideDown,
    clearAttribute,
    clearCommonReport,
    clearSelected,
    clearStation,
    clearStationSector,
    clearSubSector, detailForceLoading,
    generateDetailTbody,
    generateDetailThead,
    initDetailReport,
    removeCommonReportSlideDownId,
    sectorFromStation,
    sectorStationFromContext,
    selectEvent,
    stationFromContext,
    subSectorFromContext
} from "./event";

const {Math} = convertTo()

const summaryHelper = (params, result) => {
    const resData = result.data
    const category = params.categoryList
    const tmp = []
    for (let i = 0; i < category.length; i++) {
        tmp.push({category: category[i], data: resData.filter(item => item.attribute.categoryId === category[i].id)})
    }

    return tmp
}

const $organizationInfo = createStore({loading: false, data: {}, results: {}, error: false})
    .on(fetchOrganizationInfo.pending, (state, pending) => ({...state, loading: pending}))
    .on(fetchOrganizationInfo.fail, (state, error) => (
        {...state, data: {}, results: {}, error: error})
    )
    .on(fetchOrganizationInfo.finally, (state, res) => {
        const results = res.result.data
        const data = {
            name: results.railRoad,
            superiorName: `${results.superior.sname} ${results.superior.name}`,
            address: results.address,
            superiorPhone: results.superiorPhone,
            receptionPhone: results.receptionPhone
        }
        return {
            ...state,
            data,
            results,
            error: false
        }
    })
    .on(fetchOrganizationFullInfo.finally, (state, res) => {
        return {
            ...state,
            data: {...state.data, ...res.result.data}
        }
    })

const $allOrgInfo = createStore({loading: false, data: {}, error: false})
    .on(fetchAllOrgInfo.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchAllOrgInfo.finally, (state, response) => {
        if (response.error) {
            return {
                ...state,
                error: response.error.response
            }
        } else {
            return {
                ...state,
                error: false,
                data: response.result.data
            }
        }
    })

const $allSummary = createStore({loading: false, data: [], error: false, slideDown: {}, show: {}})
    .on(fetchSummary.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchSummary.finally, (state, res) => {
        if (res.error) {
            return {
                ...state,
                error: res.error.response,
                data: [],
                slideDown: {},
                show: {}
            }
        } else {

            const {params, result} = res
            const ids = {}
            const showIds = {}
            const category = params.categoryList
            for (let i = 0; i < category.length; i++) {
                ids[category[i].id] = false
                showIds[category[i].id] = true
            }

            return {
                ...state,
                error: false,
                data: summaryHelper(params, result),
                slideDown: ids,
                show: showIds
            }
        }
    })
    .on(changeCommonReportSlideDown, (state, data) => {
        const slideDown = {...state.slideDown, ...data}
        return {
            ...state,
            slideDown
        }
    })
    .on(removeCommonReportSlideDownId, (state, data) => {
        const tmp = {}
        const showIds = state.show

        for (let i in showIds) {
            tmp[i] = data[i] !== undefined;
        }

        return {
            ...state,
            show: tmp
        }
    })
    .on(clearCommonReport, () => ({loading: false, data: [], error: false, slideDown: {}, show: {}}))
    .on(fetchAllSummary.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchAllSummary.finally, (state, res) => {
        if (res.error) {
            return {
                ...state,
                error: res.error.response
            }
        } else {
            const {params, result} = res
            const ids = {}
            const showIds = {}
            const category = params.categoryList
            for (let i = 0; i < category.length; i++) {
                ids[category[i].id] = false
                showIds[category[i].id] = true
            }
            return {
                ...state,
                error: false,
                data: summaryHelper(params, result),
                show: showIds,
                slideDown: ids
            }
        }
    })


const $detailSummary = createStore({
        loading: false,
        data: {},
        thead: [],
        tbody: [],
        slideDown: {},
        show: {},
        error: false,
        forceLoading: false,
    }
)
    .on(fetchDetailSummary.pending, (state, loading) => ({...state, loading: loading}))
    .on(fetchDetailSummary.finally, (state, res) => {
        if (res.error) {
            return {
                ...state,
                data: {},
                thead: [],
                tbody: [],
                slideDown: {},
                show: {},
                error: res.error.response,
                loading: false,
                forceLoading: fetchAllOrgInfo()
            }
        } else {

            const {data} = state
            const dataByKM = {}
            const result = res.result.data
            if (result.length > 0) {
                for (let i = 0; i < result.length; i++) {
                    const km = Math.floor10(result[i].fromMeter / 1000)
                    const kmLength = Math.ceil10(result[i].toMeter / 1000)
                    if (dataByKM[km]) {
                        if (kmLength - km > 1) {
                            for (let j = km; j < kmLength; j++) {
                                let end = (j + 1) * 1000
                                let start = j * 1000
                                let key = j
                                if (j + 1 === kmLength) {
                                    end = result[i].toMeter
                                }

                                if (j === km) {
                                    start = result[i].fromMeter
                                }

                                if (dataByKM[key]) {
                                    dataByKM[key] = [...dataByKM[key], {
                                        ...result[i],
                                        fromMeter: start,
                                        toMeter: end,
                                        colorRed: true
                                    }]
                                } else {
                                    dataByKM[key] = [{...result[i], fromMeter: start, toMeter: end, colorRed: true}]
                                }
                            }
                        } else {
                            dataByKM[km] = [...dataByKM[km], {...result[i]}]
                        }

                    } else {
                        if (kmLength - km > 1) {
                            for (let j = km; j < kmLength; j++) {
                                let end = (j + 1) * 1000
                                let start = j * 1000
                                let key = j
                                if (j + 1 === kmLength) {
                                    end = result[i].toMeter
                                }

                                if (j === km) {
                                    start = result[i].fromMeter
                                }

                                if (dataByKM[key]) {
                                    dataByKM[key] = [...dataByKM[key], {
                                        ...result[i],
                                        fromMeter: start,
                                        toMeter: end,
                                        colorRed: true
                                    }]
                                } else {
                                    dataByKM[key] = [{...result[i], fromMeter: start, toMeter: end, colorRed: true}]
                                }
                            }
                        } else {
                            dataByKM[km] = [{...result[i]}]
                        }
                    }
                }
            }

            data[res.params.attributeId] = {...dataByKM}

            return {
                ...state,
                error: false,
                data,
            }
        }
    })
    .on(initDetailReport, (state, category) => {

        const ids = {}
        const showIds = {}
        for (let i = 0; i < category.length; i++) {
            ids[category[i].id] = false
            showIds[category[i].id] = true
        }

        return {
            ...state,
            show: showIds,
            slideDown: ids
        }
    })
    .on(generateDetailThead, (state, {startValue, endValue}) => {
        const start = Math.floor10(startValue / 1000)
        const end = Math.floor10(endValue / 1000)
        let tmp = []

        for (let i = start; i < end + 1; i++) {
            tmp.push(i)
        }

        return {
            ...state,
            thead: [{title: 'Километры', data: tmp}]
        }
    })
    .on(generateDetailTbody, (state, {startValue, endValue, attributeList, categoryList}) => {
        const start = Math.floor10(startValue / 1000)
        const end = Math.floor10(endValue / 1000)
        const data = []

        for (let i = 0; i < categoryList.length; i++) {
            const attrListByCat = attributeList.filter(item => item.categoryId === categoryList[i].id)
            const attrs = []
            for (let j = 0; j < attrListByCat.length; j++) {
                const length = []
                for (let k = start; k < end + 1; k++) {
                    length.push({
                        categoryId: categoryList[i].id,
                        id: attrListByCat[j].id,
                        start: k,
                        end: k + 1
                    })
                }
                attrs.push({
                    attributeId: attrListByCat[j].id,
                    name: attrListByCat[j].name,
                    data: length
                })
            }
            data.push({
                categoryId: categoryList[i].id,
                name: categoryList[i].name,
                data: attrs
            })
        }

        console.log(data);

        return {
            ...state,
            tbody: data
        }
    })
    .on(changeCommonReportSlideDown, (state, data) => {
        const slideDown = {...state.slideDown, ...data}
        return {
            ...state,
            slideDown
        }
    })
    .on(removeCommonReportSlideDownId, (state, data) => {
        const tmp = {}
        const showIds = state.show

        for (let i in showIds) {
            tmp[i] = data[i] !== undefined;
        }

        return {
            ...state,
            show: tmp
        }
    })
    .on(detailForceLoading, (state, status) => ({...state, forceLoading: status}))
    .on(clearCommonReport, () => {
        return {
            loading: false,
            data: {},
            thead: [],
            tbody: [],
            slideDown: {},
            show: {},
            error: false,
            forceLoading: true
        }
    })

const $reportSectorList = createStore({loading: false, data: {}, error: false})
    .on(fetchOrganizationSector.pending, (state, pending) => ({...state, loading: pending}))
    .on(fetchOrganizationSector.fail, (state, error) => (
        {...state, error: error.response, data: {}}
    ))
    .on(fetchOrganizationSector.finally, (state, res) => {
        return {
            ...state,
            data: res.result.data
        }
    })
    .on(sectorFromStation, (state, data) => ({...state, data}))

const $reportSubSectorList = createStore({loading: false, data: {}, params: {}, error: false})
    .on(fetchSubSector.pending, (state, pending) => ({...state, loading: pending}))
    .on(fetchSubSector.fail, (state, error) => ({...state, data: [], error: error.response}))
    .on(fetchSubSector.finally, (state, res) => {
        const newParams = {...state.params}
        const newData = {...state.data}
        const id = res.result.config.url.match(/\w+(.)\w+$/)[0].split('/')[1]
        const start = Number(res.params.params.start)
        const total = res.result.data.recordsFiltered
        newData[id] = res.params.clear
            ? res.result.data.data
            : newData[id] ? [...newData[id], ...res.result.data.data] : res.result.data.data
        newParams[id] = {next: (total - start) > 20}

        return {
            ...state,
            data: newData,
            params: newParams
        }
    })
    .on(subSectorFromContext, (state, data) => {
        const newData = {...state.data, ...data}
        return {
            ...state,
            data: newData,
            params: {next: false}
        }
    })
    .on(clearSubSector, () => ({loading: false, data: {}, params: {}, error: false}))

const $reportStationList = createStore({loading: false, data: [], error: false})
    .on(fetchStation.pending, (state, pending) => ({...state, loading: pending}))
    .on(fetchStation.fail, (state, error) => (
        {...state, data: [], error: error.response}
    ))
    .on(fetchStation.finally, (state, res) => {
        return {
            ...state,
            data: res.result.data,
        }
    })
    .on(stationFromContext, (state, data) => ({...state, data}))
    .on(clearStation, () => ({loading: false, data: [], error: false}))

const $reportStationSubSectorList = createStore({loading: false, data: [], result: {}, error: false})
    .on(fetchStationSubSector.pending, (state, pending) => ({...state, loading: pending}))
    .on(fetchStationSubSector.fail, (state, error) => (
        {...state, data: {}, error: error.response}
    ))
    .on(fetchStationSubSector.finally, (state, res) => {
        return {
            ...state,
            data: res.result.data,
        }
    })
    .on(clearStationSector, () => ({loading: false, data: [], result: {}, error: false}))
    .on(sectorStationFromContext, (state, data) => ({...state, data}))

const $reportCategoryList = createStore({loading: false, data: [], result: {}, error: false})
    .on(fetchCategory.pending, (state, pending) => ({...state, loading: pending}))
    .on(fetchCategory.fail, (state, error) => (
        {...state, error: error.response, data: [], result: {}}
    ))
    .on(fetchCategory.done, (state, res) => {
        const newData = res.params.clear ? res.result.data.data : [...state.data, ...res.result.data.data]
        return {
            ...state,
            data: newData,
            result: res.result.data
        }
    })

const $reportAttributeList = createStore({loading: false, data: {}, error: false})
    .on(fetchAttribute.pending, (state, pending) => ({...state, loading: pending}))
    .on(fetchAttribute.fail, (state, error) => (
        {...state, data: {}, error: error.response}
    ))
    .on(fetchAttribute.finally, (state, res) => {
        const newData = {...state.data}
        newData[res.params] = res.result.data

        return {
            ...state,
            data: newData,
        }
    })
    .on(clearAttribute, () => ({loading: false, data: {}, error: false}))

const $selectedIds = createStore({
        sector: {},
        sub_sector: {},
        category: {},
        attribute: {},
        station: {},
        station_sector: {},
        startValue: '',
        endValue: '',
        showDetail: false,
        showForm: 'common',
        mounted: false,
        type: null,
        mainPath: null
    }
)
    .on(selectEvent, (state, payload) => {
        const {key, data} = payload
        let newData = {...state}
        if (key) {
            newData[key] = state[key] ? {...state[key], ...data} : {...data}
        } else {
            newData = {...state, ...payload}
        }

        return {
            ...newData
        }
    })
    .on(clearSelected, () => ({
        sector: {},
        sub_sector: {},
        category: {},
        attribute: {},
        station: {},
        station_sector: {},
        startValue: '',
        endValue: '',
        showDetail: false,
        showForm: 'common',
        mounted: false,
        type: null,
        mainPath: null
    }))


export const $report = combine({
    $allOrgInfo,
    $allSummary,
    $detailSummary,
    $organizationInfo,
    $reportSectorList,
    $reportSubSectorList,
    $reportStationList,
    $reportStationSubSectorList,
    $selectedIds,
    $reportCategoryList,
    $reportAttributeList
})