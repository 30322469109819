import React, {useCallback} from 'react'
import {ReportFormGroup, ReportInput, ReportPlaceholder} from "../atoms";
import {useReportForm} from "../../../hooks/info";
import {UserProfileModal} from "../../../ui/molecules";
import {LISTS} from "../../../constants/common";
import {
    AttributeModal,
    CategoryModal,
    SectorModal,
    StationModal,
    StationSectorModal,
    SubSectorModal
} from "../moleculas";
import {useStore} from "effector-react";
import {
    $report,
    organizationSectorMount,
    reportCategoryMount, sectorFromStation,
} from "../../../models/report-model";
import {ReportFormLabel, ReportInputInner, ReportValues} from "../atoms";
import {Tooltip} from "antd";
import {useUrlParams} from "../../../hooks/common";
import {useLocation} from "react-router-dom";
import {isAdmin, isEmployee} from "../../../hooks/info/helper";
import {$organization} from "../../../models/organization-model";
import {$userModel} from "../../../models/user-model";

const initialParams = {
    length: 30,
    start: 0,
    clear: true
}

export const ReportFormField = ({placeholder, list, disabled, label}) => {
    const {statusModal, setStatusModal, handleAccept} = useReportForm({list})
    const {$selectedIds} = useStore($report)
    const location = useLocation()
    const {organizationId} = useUrlParams(location)
    const {$organizationsSectorsList} = useStore($organization)
    const {$userRole} = useStore($userModel)

    const renderModalComponent = () => {
        switch (list) {
            case LISTS.SECTOR:
                return (
                    <SectorModal
                        handleAccept={handleAccept}
                        setStatusModal={setStatusModal}
                        title={placeholder}
                    />
                )
            case LISTS.SUB_SECTOR:
                return (
                    <SubSectorModal
                        handleAccept={handleAccept}
                        setStatusModal={setStatusModal}
                        title={placeholder}
                    />
                )
            case LISTS.CATEGORY:
                return (
                    <CategoryModal
                        handleAccept={handleAccept}
                        setStatusModal={setStatusModal}
                        title={placeholder}
                    />
                )
            case LISTS.ATTRIBUTE:
                return (
                    <AttributeModal
                        handleAccept={handleAccept}
                        setStatusModal={setStatusModal}
                        title={placeholder}
                    />
                )
            case LISTS.STATION:
                return (
                    <StationModal
                        handleAccept={handleAccept}
                        setStatusModal={setStatusModal}
                        title={placeholder}
                    />
                )
            case LISTS.STATION_SUB_SECTOR:
                return (
                    <StationSectorModal
                        handleAccept={handleAccept}
                        setStatusModal={setStatusModal}
                        title={placeholder}
                    />
                )
            default:
                return ''
        }
    }

    const renderModal = () => {
        return (
            <UserProfileModal
                component={renderModalComponent()}
                statusModal={statusModal}
                setStatusModal={setStatusModal}
                title={list}
                width={550}
            />
        )
    }

    const getData = () => {
        switch (list) {
            case LISTS.SECTOR:
                return $selectedIds['sector']
            case LISTS.SUB_SECTOR:
                return $selectedIds['sub_sector']
            case LISTS.CATEGORY:
                return $selectedIds['category']
            case LISTS.ATTRIBUTE:
                return $selectedIds['attribute']
            case LISTS.STATION:
                return $selectedIds['station']
            case LISTS.STATION_SUB_SECTOR:
                return $selectedIds['station_sector']
            default:
                return {}
        }
    }

    const data = Object.values(getData())
    const getTitle = data.map(item => item.name).join(', ')

    const getSectorList = useCallback((orgId) => {
        const context = $organizationsSectorsList.data
        if (isAdmin($userRole)) {
            organizationSectorMount(orgId)
        }

        if (isEmployee($userRole)) {
            const data = context.map(({subSectors, ...item}) => item)
            sectorFromStation(data)
        }
    }, [$userRole, $organizationsSectorsList.data])

    const handleClick = useCallback(() => {
        setStatusModal(true)

        if (list === LISTS.SECTOR && organizationId) {
            getSectorList(organizationId)
        }

        if (list === LISTS.CATEGORY) {
            reportCategoryMount(initialParams)
        }
    }, [getSectorList, list, organizationId, setStatusModal])

    return (
        <>
            {renderModal()}
            <ReportFormGroup>
                <ReportFormLabel>
                    {label}
                </ReportFormLabel>
                <Tooltip title={data.length > 1 && getTitle}>
                    <ReportInput disabled={disabled} onClick={handleClick}>
                        <ReportInputInner>
                            {
                                data && data.length > 0
                                    ? data.map((item, idx, arr) => (
                                        <ReportValues key={item.id}>
                                        <span>
                                            {`${item.name}${(arr.length - 1) !== idx ? ',' : ''}`}
                                        </span>
                                        </ReportValues>
                                    ))
                                    : <ReportPlaceholder>
                                        {placeholder}
                                    </ReportPlaceholder>
                            }
                        </ReportInputInner>
                    </ReportInput>
                </Tooltip>
            </ReportFormGroup>
        </>
    )
}