import React, {Fragment, useEffect, useState} from 'react'
import {InputSystem, SelectSys} from "../../../ui/molecules";
import {$isDataPending, positionListMount} from "../model";
import {useStore} from "effector-react";
import {Form, Select} from "antd";
import {AvatarUpload} from "../../avatar-upload";
import {ModalViewTitle} from "../atoms";
import {Button, ModalFooterWrapper} from "../../../ui/atoms";
import {$userModel} from "../../../models/user-model";
import {useUserProfileEdit} from "../../../hooks/user/user-profile-edit";
import {$organization, orgListMount} from "../../../models/organization-model";
import {useTranslation} from "react-i18next";
import Cookies from 'js-cookie';
import {SelectSystem} from "../../../ui/molecules/select";

const Option = Select.Option


export const EditProfile = (props) => {
    const {t} = useTranslation();
    const {setStatusModal} = props
    const {$signedUserInfo} = useStore($userModel)
    const {updateUserInfo} = useUserProfileEdit()
    const [name, setName] = useState('')
    const [sname, setSname] = useState('')
    const [username, setUsername] = useState('');
    const [phone, setPhone] = useState('')
    const [fileId, setFileId] = useState(null)

    const {data} = $signedUserInfo


    useEffect(() => {
        setName(data.name)
        setSname(data.sname)
        setUsername(data.username)
        setPhone(data.phone)
        setFileId(data.image)
    }, [data])

    const handleSave = () => {
        const $data = {
            ...data,
            image: fileId,
            name: name,
            sname: sname,
            phone: phone,
            username: username
        }
        updateUserInfo($data)
    }

    return (
        <Fragment>
            <Form.Item style={{textAlign: 'center'}}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <AvatarUpload file={fileId} setFileId={setFileId} imageSize='96px'/>
                </div>

            </Form.Item>
            <Form.Item>
                <InputSystem
                    value={sname}
                    change={setSname}
                    type='text'
                    placeholder={t('enter-last-name')}
                    label={t('last-name')}
                />
            </Form.Item>
            <Form.Item>
                <InputSystem
                    value={name}
                    change={setName}
                    type='text'
                    placeholder={t('enter-name')}
                    label={t('name')}
                />
            </Form.Item>
            <Form.Item>
                <InputSystem
                    value={phone}
                    change={setPhone}
                    type='text'
                    placeholder={t('phone-number')}
                    label={t('enter-phone-number')}
                />
            </Form.Item>
            <ModalFooterWrapper>
                <Button
                    type='link'
                    onClick={() => setStatusModal(false)}
                >
                    {t('cancel')}
                </Button>
                <Button
                    onClick={handleSave}
                    disabled={
                        !name
                        || !sname
                        || !username
                        || !phone
                        || !fileId
                    }
                >
                    {t('save')}
                </Button>
            </ModalFooterWrapper>
        </Fragment>
    )
}

export const ChangePassword = (props) => {
    const {t} = useTranslation();
    const {setStatusModal} = props
    const {changeUserPassword} = useUserProfileEdit()
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [errors, setErrors] = useState(undefined)


    const changePassword = () => {
        if (confirmPassword !== newPassword) {
            setErrors(t('password-mismatch'))
        } else {
            const data = {
                newPassword: newPassword,
                password: oldPassword
            }
            changeUserPassword(data)
        }
    }

    return (
        <div>
            <ModalViewTitle>
                {t('change-password')}
            </ModalViewTitle>
            <Form.Item>
                <InputSystem
                    value={oldPassword}
                    change={setOldPassword}
                    type='password'
                    label={t('old-password')}
                    placeholder={t('enter-old-password')}
                />
            </Form.Item>
            <Form.Item>
                <InputSystem
                    value={newPassword}
                    change={setNewPassword}
                    type='password'
                    placeholder={t('enter-new-password')}
                    label={t('new-password')}
                />
            </Form.Item>
            <Form.Item>
                <InputSystem
                    value={confirmPassword}
                    change={setConfirmPassword}
                    type='password'
                    placeholder={t('repeat-password')}
                    label={t('password')}
                />
                {errors && <span>{errors}</span>}
            </Form.Item>
            <ModalFooterWrapper>
                <Button
                    type='link'
                    onClick={() => setStatusModal(false)}
                >
                    {t('cancel')}
                </Button>
                <Button
                    onClick={changePassword}
                    htmlType="submit"
                    disabled={
                        !oldPassword
                        || !newPassword
                        || !confirmPassword
                    }
                >
                    {t('save')}
                </Button>
            </ModalFooterWrapper>
        </div>
    )
}

export const ChangeLanguage = (props) => {
    const {t, i18n} = useTranslation();
    const {setStatusModal} = props;
    const [lang, setLang] = useState('uz');


    const changeLanguage = () => {
        Cookies.set('lang', lang);
        i18n.changeLanguage(lang);
        setStatusModal(false)
    };

    useEffect(() => {
        if (Cookies.get('lang')) {
            setLang(Cookies.get('lang'))
        } else {
            setLang('uz')
        }
    }, []);

    const options = [
        {
            value: 'uz',
            name: 'UZ'
        },
        {
            value: 'ru',
            name: 'RU'
        },
        {
            value: 'en',
            name: 'EN'
        }
    ]

    return (
        <div>
            <ModalViewTitle>
                {t('change-language')}
            </ModalViewTitle>
            <Form.Item>
                <SelectSystem options={options} change={setLang} value={lang} />
            </Form.Item>
            <ModalFooterWrapper>
                <Button
                    type='link'
                    onClick={() => setStatusModal(false)}
                >
                    {t('cancel')}
                </Button>
                <Button
                    onClick={changeLanguage}
                    htmlType="submit"
                >
                    {t('save')}
                </Button>
            </ModalFooterWrapper>
        </div>
    )
}

export const ChangeOrganization = ({setStatusModal}) => {
    const {t} = useTranslation();
    const {$orgList} = useStore($organization)
    const {UpdateUserRequest} = useUserProfileEdit()
    const {$signedUserInfo} = useStore($userModel)
    const [organizationId, setOrganizationId] = useState({value: undefined, label: undefined, key: undefined})
    const {data} = $signedUserInfo

    const changeOrganization = () => {
        const id = String(organizationId.value)
        UpdateUserRequest('organization', id)
    }

    useEffect(() => {
        const params = {
            length: 30,
        };
        orgListMount(params)
    }, [])


    useEffect(() => {
        if ($orgList.filtered && $orgList.filtered.length > 0) {
            setOrganizationId({
                value: Number(data.organizations[0].id),
                label: data.organizations[0].name,
                key: Number(data.organizations[0].id),
            })
        }

    }, [data, $orgList])


    return (
        <Form>
            <ModalViewTitle>
                {t('change-organization')}
            </ModalViewTitle>
            <Form.Item>
                <SelectSys
                    loading={$orgList.loading}
                    value={organizationId}
                    change={setOrganizationId}
                    options={$orgList.filtered}
                    placeholder={t('organizations')}
                    labelInValue={true}
                />
            </Form.Item>
            <ModalFooterWrapper>
                <Button
                    type='link'
                    onClick={() => setStatusModal(false)}
                >
                    {t('cancel')}
                </Button>
                <Button
                    disabled={!organizationId}
                    onClick={changeOrganization}
                >
                    {t('next')}
                </Button>
            </ModalFooterWrapper>
        </Form>
    )
}

export const ChangePosition = ({setStatusModal}) => {
    const {t} = useTranslation();
    const {UpdateUserRequest} = useUserProfileEdit()
    const {$positionList} = useStore($isDataPending)
    const {$signedUserInfo} = useStore($userModel)
    const [positionId, setPositionId] = useState({value: undefined, label: undefined, key: undefined})

    const {data} = $signedUserInfo

    const changePosition = () => {
        const id = String(positionId.value)
        UpdateUserRequest('position', id)
    }

    useEffect(() => {
        setPositionId({
            value: data.position.id,
            label: data.position.title,
            key: data.position.id
        })
    }, [data])

    useEffect(() => {
        if ($positionList.data.length === 0) {
            positionListMount()
        }
    }, [$positionList.data])

    return (
        <Form>
            <ModalViewTitle>
                {t('change-position')}
            </ModalViewTitle>
            <Form.Item>
                <SelectSys
                    loading={$positionList.loading}
                    value={positionId}
                    change={setPositionId}
                    options={$positionList.filtered}
                    placeholder={t('positions')}
                    labelInValue={true}
                />
            </Form.Item>
            <ModalFooterWrapper>
                <Button
                    type='link'
                    onClick={() => setStatusModal(false)}
                >
                    {t('cancel')}
                </Button>
                <Button
                    disabled={!positionId}
                    onClick={changePosition}
                >
                    {t('save')}
                </Button>
            </ModalFooterWrapper>
        </Form>
    )
}

