import React, {useCallback, useEffect, useState} from 'react';
import {getAttributeInfo} from "../api";
import {withRouter} from "react-router-dom";
import {useStore} from "effector-react";
import {$isDataPending} from "../model";
import Moment from "react-moment";
import {convertKilo} from "../../../helpers";
import {Spin} from "antd";
import {timeEvent} from "../model/events";


const AttributeInfoView = (props) => {
    const {
        location,
        id,
        colorArray,
        setColorArray
    } = props;
    const isDataPending = useStore($isDataPending);
    //status
    const [loading, setLoading] = useState(false);

    //data
    const [data, setData] = useState(undefined);
    const [sectorId, setSectorId] = useState(undefined);
    const [sectorPoint, setSectorPoint] = useState(false);
    const [fromMeter, setFromMeter] = useState(false);
    const [toMeter, setToMeter] = useState(false);


    useEffect(() => {
        const paramsString = location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.has('subSectorId')) {
            setSectorId(searchParams.get('subSectorId'));
        } else {
            setSectorId(searchParams.get('sectorId'));
        }
        if (searchParams.has('sectorPoint')) {
            setSectorPoint(searchParams.get('sectorPoint'));
        }
    }, [location]);

    useEffect(() => {
        const params = {
            ts: isDataPending.$timeStore,
        };
        if (sectorId && sectorPoint) {
            setLoading(true);
            getAttributeInfo(
                id,
                sectorId,
                Number(sectorPoint) * 1000,
                Number(sectorPoint) * 1000 + 1000,
                params)
                .then(response => {
                    if (response.status === 200) {
                        setLoading(false);
                        if (response.data[0]) {
                            setData(response.data[0]);
                            const color = colorArray;
                            color[id] = {
                                color: response.data[0].color
                            }
                            setColorArray([...color]);
                            setFromMeter(convertKilo(response.data[0].fromMeter));
                            setToMeter(convertKilo(response.data[0].toMeter));
                        } else{
                            setToMeter(false);
                            setFromMeter(false);
                            setData(undefined);
                        }
                    }
                })
                .catch(error => {
                    setLoading(false);
                    console.error('entryError', error.response)
                })
        }
    }, [isDataPending.$timeStore, id]);

    useEffect(() => {
        timeEvent(Date.now());
    }, [sectorId, sectorPoint]);


    return (
        <Spin spinning={loading}>
            {
                Number(fromMeter) === 0 ? Number(toMeter) === 0 ? '' :
                `${fromMeter}-${toMeter}м / ` : `${fromMeter}-${toMeter}м / `
            }
            {
                data &&
                data.entry && data.entry && data.entry.value ? data.entry.name ? data.entry.name : data.entry.type === 'DATE' ? <Moment locale='ru' format="DD.MM.YYYY" date={data.entry.value}/> : data.entry.value : ''
            }
        </Spin>
    )
};

export const AttributeInfo = withRouter(AttributeInfoView);
