import React from 'react';
import {NavLink, withRouter} from "react-router-dom";
import {Menu} from 'antd';
import {MenuItem} from './menu-item';
import {useStore} from "effector-react";
import {useUrlParams} from "../../../hooks/common";
import {TabCustom} from "../atoms";
import {$organization} from "../../../models/organization-model";
import {hoverSector} from "../../../models/google-map";


const {SubMenu} = Menu;

const SectorsListView = (props) => {
    const {location} = props;
    const organization = useStore($organization);
    const {sectorId, urlParams} = useUrlParams(location, ['sectorId', 'subSectorId', 'stationId']);

    return (
        <TabCustom>
            <Menu
                style={{width: '100%'}}
                mode="inline"
                openKeys={sectorId ? [sectorId] : []}
            >
                {
                    organization.$organizationsSectorsList.data && organization.$organizationsSectorsList.data.length > 0 &&
                        organization.$organizationsSectorsList.data.map(item =>
                            <SubMenu
                                key={item.id.toString()}
                                title={
                                    <NavLink
                                        onMouseEnter={() => hoverSector({id: item.id, enter: true})}
                                        onMouseLeave={() => hoverSector({id: item.id, enter: false})}
                                        className='tab-link'
                                        activeClassName='active-tab-link'
                                        isActive={() => {
                                            return Number(sectorId) === Number(item.id)
                                        }}
                                        to={{
                                            pathname: '/',
                                            search: `sectorId=${item.id}${urlParams ? '&' + urlParams : ''}`
                                        }}
                                        key={item.id}
                                    >
                                        <span>{item.label}</span>
                                    </NavLink>
                                }
                            >
                                    <MenuItem
                                        key={item.id}
                                        id={item.id}
                                        subSectors={item.subSectors}
                                    />
                            </SubMenu>
                        )
                }
            </Menu>
        </TabCustom>
    )
};

export const SectorsList = withRouter(SectorsListView);
