import {createStore} from "effector";
import {fetchGetAttrSector, fetchGoogleMapPoints} from "./effects";
import {
    checkOrganizationEvent,
    filterParentPointsEvent,
    filterPointsEvent,
    hoverSector,
    polyLineEvent,
    setDBPoints
} from "./events";
import Cookies from 'js-cookie';
import {initDB, useIndexedDB} from "react-indexed-db";
import {DBConfig} from "../../DBConfig";

initDB(DBConfig);


const {update} = useIndexedDB('googlePoints');


export const $googlePoints = createStore({
    loading: false,
    data: [],
    filter: [],
    selectSectors: [],
    enterSector: [],
    error: false
})
    .on(fetchGoogleMapPoints.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchGoogleMapPoints.finally, (state, response) => {
        if (response.error) {
            return {
                ...state,
                error: response.error.response
            }
        } else {
            const data = [];
            const filter = {};
            const dataPoints = response.result.data;
            for (let i = 0; i < dataPoints.length; i++) {
                if (dataPoints[i][5] === null && !data.some(element => element.id === dataPoints[i][4])) {
                    const filteredFiles = dataPoints.filter((e) => {
                        return e[4] === dataPoints[i][4]
                    });
                    filter[dataPoints[i][4]] = {
                        weight: 2,
                        status: false,
                        parentId: dataPoints[i][5],
                        id: dataPoints[i][4],
                        points: filteredFiles.map(item => ({
                            lat: item[2],
                            lng: item[3],
                            kilo: item[0],
                        })),
                        default: filteredFiles.map(item => ({
                            lat: item[2],
                            lng: item[3],
                            kilo: item[0],
                        }))
                    };
                    data.push({
                        weight: 2,
                        status: false,
                        parentId: dataPoints[i][5],
                        id: dataPoints[i][4],
                        points: filteredFiles.map(item => ({
                            lat: item[2],
                            lng: item[3],
                            kilo: item[0],
                        })),
                        default: filteredFiles.map(item => ({
                            lat: item[2],
                            lng: item[3],
                            kilo: item[0],
                        }))
                    });
                } else if (!data.some(element => element.id === dataPoints[i][4])) {
                    const filteredFiles = dataPoints.filter((e) => {
                        return e[4] === dataPoints[i][4]
                    });

                    data.push({
                        weight: 2,
                        status: false,
                        parentId: dataPoints[i][5],
                        id: dataPoints[i][4],
                        points: filteredFiles.map(item => ({
                            lat: item[2],
                            lng: item[3],
                            kilo: item[0],
                        })),
                        default: filteredFiles.map(item => ({
                            lat: item[2],
                            lng: item[3],
                            kilo: item[0],
                        }))
                    })
                }
            }
            update({
                id: 1,
                pointArray: JSON.stringify({
                    ...state,
                    error: false,
                    data: data,
                    defaultFilter: filter,
                    filter: filter
                })
            }).then(
                _ => {
                    Cookies.set('tokenTime', Date.parse(new Date()))
                    // console.log('ID Generated: ', event);
                },
                _ => {
                    // console.log(error);
                }
            );
            return {
                ...state,
                error: false,
                data: data,
                defaultFilter: filter,
                filter: filter
            }
        }
    })
    .on(hoverSector, (state, response) => {

        const points = []
        const filter = {}
        if (response.enter) {

            const fastFilter = state.data.filter(item => item.id === response.id)

            if (fastFilter && fastFilter.length > 0) {
                fastFilter[0].points.map(item => points.push(item))
            }
            filter[Number(response.id)] = {
                id: response.id,
                points: points,
                weight: response.enter ? 0 : 2,
                status: response.enter
            }
        }
        return {
            ...state,
            enterSector: {...filter}
        }
    })
    .on(setDBPoints, (state, response) => {
        return {
            ...state,
            ...response,
            loading: false
        }
    })
    .on(checkOrganizationEvent, (state, response) => {
        const defaultPoints = {}

        response.map(item => defaultPoints[item.id] = {
            id: item.id,
            points: state.filter[item.id].points,
            weight: 0
        })
        return {
            ...state,
            selectSectors: {...defaultPoints}
        }
    })
    .on(filterPointsEvent, (state, response) => {
        return {
            ...state,
            selectSectors: {...response}
        }
    });

export const $filteredPoints = createStore({loading: false, data: [], error: false})
    .on(filterParentPointsEvent, (state, response) => {
        return {
            ...state,
            data: response
        }
    })
    .on(fetchGetAttrSector.pending, (state, pending) => {

        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchGetAttrSector.finally, (state, response) => {
        if (response.error) {
            return {
                ...state,
                error: response.error.response
            }
        } else {
            const dataKilo = [];
            if (response.result.data && response.result.data.length > 0) {
                for (let i = 0; i < response.result.data.length; i++) {
                    if (Number(response.result.data[i].toMeter) - Number(response.result.data[i].fromMeter) > 1000) {
                        for (let q = Math.floor10(response.result.data[i].fromMeter / 1000); q < Math.floor10(response.result.data[i].toMeter / 1000); q++) {
                            dataKilo[q] = {
                                color: response.result.data[i].entry.color,
                                weight: 2
                            };
                        }

                    } else {
                        dataKilo[Number(Math.floor10(response.result.data[i].fromMeter / 1000))] = {
                            color: response.result.data[i].entry.color,
                            weight: 2
                        };
                    }
                }
            }


            const filterKiloFunction = (data) => {
                const filteredKilo = [];
                for (let i = data.points[0].kilo; i < (data.points[data.points.length - 1].kilo + 1); i++) {
                    filteredKilo.push({
                        kilo: i,
                        kiloPoints: i < (data.points[data.points.length - 1].kilo + 1) ? [...data.points.filter(item => item.kilo === i), data.points.filter(item => item.kilo === i + 1)[0]]
                            : data.points.filter(item => item.kilo === i),
                        ...dataKilo[i]
                    })
                }
                return filteredKilo
            };

            const data = {...state.data}

            if (state.data[response.params.sectorId]){
                data[response.params.sectorId] = {
                    ...state.data[response.params.sectorId],
                    kilo: filterKiloFunction(state.data[response.params.sectorId])
                }
            }



            return {
                data
            }
        }
    });

export const $polyGonList = createStore({loading: false, data: [], error: false})
    .on(polyLineEvent, (state, response) => {
        const data = {}
        response.stationList.map(item => {
                // console.log(response.points[item.parent.id])
                const filtered = response.points[item.parent.id].points.filter(pointList => pointList.kilo >= item.parentStartKm && pointList.kilo <= item.parentStopKm)
                data[item.id] = {
                    points: filtered,
                    coverage: item.coverage
                }
            }
        )
        return {
            ...state,
            data: data
        }
    });