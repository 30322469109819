import React, {useState, useEffect} from 'react'
import {ReportListWrapper, ReportModalTitle, ReportModalWrapper} from "../atoms";
import {useStore} from "effector-react";
import {$report} from "../../../models/report-model";
import {Button, ModalFooterWrapper} from "../../../ui/atoms";
import {ReportListsItem} from "./report-list-item";
import {matchByParent} from "../../../hooks/info/helper";
import {useTranslation} from "react-i18next";

export const SectorModal = ({handleAccept, setStatusModal, title}) => {
    const {$selectedIds, $reportSectorList} = useStore($report)
    const [selected, setSelected] = useState({})
    const {loading, data} = $reportSectorList
    const {t} = useTranslation()

    useEffect(() => {
        if (Object.values($selectedIds.sector).length > 0) {
            setSelected($selectedIds.sector)
        }else {
            setSelected(null)
        }
    }, [$selectedIds.sector])

    const handleClick = () => {
        const data = {...selected}
        const {station_sector, sub_sector, station} = $selectedIds
        const result = matchByParent({station_sector, sub_sector, station}, data)
        handleAccept({...$selectedIds, sector: {...data}, ...result})
    }


    return (
        <ReportModalWrapper>
            <ReportModalTitle>
                {title}
            </ReportModalTitle>
            {
                !loading && data.length > 0 && (
                    <ReportListWrapper>
                        {
                            data.map((item) => (
                                <ReportListsItem
                                    key={item.id}
                                    item={item}
                                    selected={selected}
                                    setSelected={setSelected}
                                    multipleSelect={$selectedIds.showForm === 'common'}
                                />
                            ))
                        }
                    </ReportListWrapper>
                )
            }
            <ModalFooterWrapper>
                <Button
                    type='link'
                    onClick={() => setStatusModal(false)}
                >
                    {t('cancel')}
                </Button>
                <Button onClick={handleClick}>
                    {t('apply')}
                </Button>
            </ModalFooterWrapper>
        </ReportModalWrapper>
    )
}