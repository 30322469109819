import {createEvent} from "effector";
import {fetchGetAttrSector, fetchGoogleMapPoints} from "./effects";

export const googlePointPageMount = createEvent();
export const filterPointsEvent = createEvent();
export const filterParentPointsEvent = createEvent();
export const getAttrInfoSector = createEvent();
export const setDBPoints = createEvent();
export const hoverSector = createEvent();
export const checkOrganizationEvent = createEvent()
export const polyLineEvent = createEvent()

googlePointPageMount.watch(fetchGoogleMapPoints);
getAttrInfoSector.watch(fetchGetAttrSector);