import React, {Fragment} from "react";
import {CustomTable, ContentTitle} from "../atoms";
import {useStore} from "effector-react";
import {$isDataPending} from "../../../models/info-table-model";
import {ModalAttribute} from "../../../ui/molecules";
import {InfoTableHooks} from "../../../hooks/dashboard";
import {$sectorInfo} from "../model/stores";
import {useTranslation} from "react-i18next";

export const InfoTable = () => {
    const isDataPending = useStore($isDataPending);
    const sectorInfo = useStore($sectorInfo);
    const { t } = useTranslation();


    const {
        categoryId,
        columns,
        setStatusModal,
        attributeInfo,
        statusModal
    } = InfoTableHooks();

    return (
        <Fragment>
            <ModalAttribute
                info={sectorInfo.data}
                categoryId={categoryId}
                setStatusModal={setStatusModal}
                attributeInfo={attributeInfo}
                statusModal={statusModal}
            />
            <ContentTitle>{t('info')}</ContentTitle>
            <CustomTable
                loading={isDataPending.$attributeList.loading}
                className='second-color'
                showHeader={false}
                bordered={true}
                title={false}
                pagination={false}
                dataSource={isDataPending.$attributeList.data}
                columns={columns}
            />
        </Fragment>
    );
};