import {createEvent} from "effector";
import {
  fetchStationList
} from "./effects";


export const stationListMount = createEvent();
export const stationSubsMount = createEvent()

stationListMount.watch(fetchStationList);
