import React from "react";
import {combine, createStore} from "effector";
import {Tooltip} from "antd";
import {getCategoryAttrListEvent} from "./event";


const getAttrInfo = (id) => {
    return {
        id: id
    }
};

export const $attributeList = createStore({loading: false, data: [], error: false})
    .on(getCategoryAttrListEvent, (params, response) => {
        if (response.data && response.data.length > 0) {
            return {
                ...params,
                data: response.data.map(item => {
                    return {
                        ...item,
                        key: item.id,
                        name: <Tooltip overlayStyle={{borderRadius: 15}} placement="topLeft" title={item.description}>
                            <div>{item.name}</div>
                        </Tooltip>,
                        value: item.id,
                        data: getAttrInfo(item.id)
                    }
                }),
                filtered: response.data.map(item => ({value: item.id, label: item.name})),
                error: false
            };
        }

    });

export const $isDataPending = combine({
    $attributeList
});