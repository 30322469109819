import {useStore} from "effector-react";
import {useUrlParams} from "../../../hooks/common";
import {TabCustom} from "../atoms";
import {Menu} from "antd";
import {NavLink, useLocation} from "react-router-dom";
import {MenuItemStation} from "./menu-item";
import React from "react";
import {$isDataPending} from "../../../models/station-model";


const {SubMenu} = Menu;


export const StationList = () => {
    const location = useLocation();
    const isDataPending = useStore($isDataPending);
    const {stationId, urlParams} = useUrlParams(location, ['stationId', 'subSectorId']);

    return (
        <TabCustom>
            <Menu
                style={{width: '100%'}}
                mode="inline"
                openKeys={stationId ? [stationId] : []}
            >
                {
                    isDataPending.$stationList.data && isDataPending.$stationList.data.length > 0 &&
                    isDataPending.$stationList.data.map(item =>
                        <SubMenu
                            key={item.id.toString()}
                            title={
                                <NavLink
                                    className='tab-link'
                                    activeClassName='active-tab-link'
                                    isActive={() => {
                                        return Number(stationId) === Number(item.id)
                                    }}
                                    to={{
                                        pathname: '/',
                                        search: `stationId=${item.id}${urlParams ? '&' + urlParams : ''}`
                                    }}
                                    key={item.id}
                                >
                                    <span>{item.name}</span>
                                </NavLink>
                            }
                        >
                            <MenuItemStation
                                key={item.id}
                                id={item.id}
                                subSectors={item.subSectors}
                            />
                        </SubMenu>
                    )
                }
            </Menu>
        </TabCustom>
    )
}