import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Cookies from 'js-cookie';
import en from './locale/en.json';
import ru from './locale/ru.json';
import uz from './locale/uz.json'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en,
    ru,
    uz
};

const lang = Cookies.get('lang') ? Cookies.get('lang') : 'uz'

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: lang,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;