import React, { useEffect, useState } from "react";
import { JournalBlock } from './journal-block';
import { ContentTitle, JournalGroup } from "../atoms";
import { useStore } from "effector-react";
import { $isDataPending, logList } from "../model";
import { ButtonGray, GridColumn } from "../../../ui/atoms";
import { Monitoring } from "./monitoring";
import {useTranslation} from "react-i18next";

export const UserActions = () => {
    const isDataPending = useStore($isDataPending);
    const [start, setStart] = useState(0);
    const [showMonitoring, setShowMonitoring] = useState(false);
    const {t} = useTranslation();
    // const [logData, setLogData] = useState([]);

    const uploadJournal = () => {
        const params = {
            ts: isDataPending.$timeStore,
            length: 10,
            start: start + 10
        };
        logList(params);
        setStart(start + 10);
    };

    useEffect(() => {
        const params = {
            ts: isDataPending.$timeStore,
            length: 10,
            start
        };
        logList(params)
    }, [isDataPending.$timeStore]);


    return (
        <div>
            <GridColumn column={2}>
                <ContentTitle style={{ cursor: 'pointer' }} onClick={() => setShowMonitoring(false)}>{t('action-log')}</ContentTitle>
                <ContentTitle style={{ cursor: 'pointer' }} onClick={() => setShowMonitoring(true)}>{t('monitoring')}</ContentTitle>
            </GridColumn>
            {
                showMonitoring
                    ? <Monitoring />
                    : <>
                        <JournalGroup>
                            {
                                isDataPending.$logList.data &&
                                isDataPending.$logList.data.map((item, index) => <JournalBlock key={index} dataInfo={item} />)
                            }
                        </JournalGroup>
                        <ButtonGray style={{ padding: '0 30px !important', margin: '15px 0 !important' }} onClick={() => uploadJournal()}>{t('show-previous')}</ButtonGray>
                    </>
            }
        </div>
    )
};
