import {useState} from "react"
import {getInfo, objToArray} from "./helper";
import {$report, allSummaryMount, selectEvent} from "../../models/report-model";
import {useStore} from "effector-react";

export const useAllReport = () => {
    const [date, setDate] = useState(new Date())
    const {$selectedIds, $reportAttributeList} = useStore($report)
    const {category, attribute} = $selectedIds

    const changeDate = (date) => {
        setDate(date.valueOf())
    }

    const onSubmit = () =>{
        let attributeData

        const selectedAttribute = Object.values(attribute)
        const attributeList = objToArray($reportAttributeList.data)

        if (Object.keys(category).length > 0) {
            if (selectedAttribute.length > 0) {
                attributeData = getInfo(selectedAttribute)
            } else {
                attributeData = getInfo(attributeList)
            }

            if (attributeData) {
                const commonReportParams = {
                    categoryList: Object.values(category),
                    data: {
                        attributeIds: attributeData.ids,
                        fromMeter: 0,
                        toMeter: 100000000,
                        ts: date.getTime()
                    }
                }
                selectEvent({type: 'common'})
                allSummaryMount(commonReportParams)
            }
        }
    }

    return {
        date,
        changeDate,
        onSubmit
    }
}