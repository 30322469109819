import React from 'react';
import {useStore} from "effector-react";
import {GoogleApiWrapper, InfoWindow, Map, Polyline} from "google-maps-react";
import {MapAttrGroup, MapAttrGroupItems, MapAttrGroupTitle, MapContainer, MapLoader, SectorInfoWindow} from "../atoms";
import {GetPointsHooks} from "../../../hooks/dashboard";
import {$googlePoints, $polyGonList, $filteredPoints} from "../../../models/google-map";
import {$isDataPending} from "../../../models/info-table-model";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ExpandSvg, CollapseSvg} from "../../../media";


const GoogleMapComponentView = (props) => {
    const {t} = useTranslation();
    const googlePoints = useStore($googlePoints);
    const filteredPoints = useStore($filteredPoints);
    const isDataPending = useStore($isDataPending);
    const polyGonList = useStore($polyGonList).data

    const {
        onPolylineClick,
        centerWindow,
        showingInfoWindow,
        sectorInfoData,
        mapLoaded,
        sectorId,
        parentSymbol,
        symbolThree,
        urlParams,
        zoomChange,
        zoom,
        attributeId,
        attrStatus,
        setAttrStatus,
        setShowingInfoWindow,
        categoryName,
        centerInfoWindow,
        stationView
    } = GetPointsHooks(props);

    const checkPoint = (data) => {
        const filterData = []
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if (data[i] !== undefined) {
                    filterData.push(data[i])
                }
            }
            return filterData
        }
    }

    return (
        <MapContainer>
            <MapLoader status={googlePoints.loading}>
                {t('loading-map')}...
            </MapLoader>
            <Map
                google={props.google}
                // style={{height: '600px', width: '100%', padding: '24px'}}
                center={centerWindow}
                initialCenter={centerWindow}
                zoom={zoom}
                // fullscreenControl={false}
                onReady={(mapProps, map) => mapLoaded(mapProps, map)}
                onZoom_changed={(mapProps, map) => zoomChange(mapProps, map)}
            >
                {
                    googlePoints.enterSector &&
                    Object.keys(googlePoints.enterSector).map(index =>
                        googlePoints.enterSector[index].id && googlePoints.enterSector[index].points &&
                        <Polyline
                            text='dsfdsf'
                            key={googlePoints.enterSector[index].id}
                            path={googlePoints.enterSector[index].points}
                            strokeColor={'#5681FF'}
                            strokeOpacity={1}
                            strokeWeight={zoom - googlePoints.enterSector[index].weight}
                            onClick={(props, e) => onPolylineClick(props, e, googlePoints.enterSector[index])}
                        />
                    )
                }

                {
                    zoom > 11 ? sectorId &&
                    Object.keys(filteredPoints.data) && Object.keys(filteredPoints.data).length > 0 &&
                    Object.keys(filteredPoints.data).map((index) =>
                        filteredPoints.data[index].kilo && filteredPoints.data[index].kilo.length > 0 && filteredPoints.data[index].kilo.map((item, i) =>
                            <Polyline
                                text={item.kilo}
                                key={i}
                                icons={[
                                    {
                                        icon: symbolThree,
                                        offset: '0%'
                                    },
                                    {
                                        icon: parentSymbol,
                                        offset: '100%'
                                    }
                                ]}
                                path={item && item.kiloPoints && checkPoint(item.kiloPoints)}
                                strokeColor={item.color ? item.color : 'rgba(24, 144, 255, 0.50)'}
                                stroke Opacity={1}
                                strokeWeight={zoom - filteredPoints.data[index].weight}
                                onClick={(props, e) => onPolylineClick(props, e, filteredPoints.data[index])}

                            />
                        )) :
                        googlePoints.selectSectors &&
                        Object.keys(googlePoints.selectSectors).map(index =>
                            googlePoints.selectSectors[index].id && googlePoints.selectSectors[index].points && !googlePoints.enterSector[index] &&
                            <Polyline
                                text='dsfdsf'
                                key={googlePoints.selectSectors[index].id}
                                path={googlePoints.selectSectors[index].points}
                                strokeColor={'#989898'}
                                strokeOpacity={1}
                                strokeWeight={zoom - googlePoints.selectSectors[index].weight}
                                onClick={(props, e) => onPolylineClick(props, e, googlePoints.selectSectors[index])}
                            />
                        )
                }

                {
                    googlePoints.filter &&
                    Object.keys(googlePoints.filter).map(index =>
                        googlePoints.filter[index].id && googlePoints.filter[index].points && !googlePoints.selectSectors[index] && !googlePoints.enterSector[index] &&
                        <Polyline
                            text='dsfdsf'
                            key={googlePoints.filter[index].id}
                            path={googlePoints.filter[index].points}
                            strokeColor={'#D6D6D6'}
                            strokeOpacity={1}
                            strokeWeight={4}
                            onClick={(props, e) => onPolylineClick(props, e, googlePoints.filter[index])}
                        />
                    )
                }
                {/*POLYGON*/}
                {/*{*/}
                {/*    ((zoom > 9 && zoom < 11) || (zoom > 12)) && stationView && Object.keys(polyGonList).map(index => polyGonList[index] && polyGonList[index].points && polyGonList[index].points.length > 0 &&*/}
                {/*        <Polyline*/}
                {/*            text='dsfdsf'*/}
                {/*            path={polyGonList[index].points}*/}
                {/*            key={index}*/}
                {/*            strokeColor={'red'}*/}
                {/*            strokeOpacity={1}*/}
                {/*            strokeWeight={polyGonList[index].coverage - (zoom * 2)}*/}
                {/*        />*/}
                {/*    )*/}
                {/*}*/}
                <InfoWindow
                    onClose={() => setShowingInfoWindow(false)}
                    visible={showingInfoWindow}
                    position={centerInfoWindow}
                >
                    <SectorInfoWindow>
                        <div>
                            <span>{t('name1')}:</span>
                            <span>{sectorInfoData.name}</span>
                        </div>
                        {
                            zoom > 11 && sectorId &&
                            <div>
                                <span style={{color: '#3366FF'}}>KM:</span>
                                <span style={{color: '#3366FF'}}>{sectorInfoData.kilo} км</span>
                            </div>
                        }
                        {/*<div>*/}
                        {/*    <span>{t('count-directions')}:</span>*/}
                        {/*    <span>{sectorInfoData.parent && sectorInfoData.parent.length}</span>*/}
                        {/*</div>*/}
                        {
                            !sectorId &&
                            <div>
                                <span>{t('count-driving')}: </span>
                                <span>{sectorInfoData.subsLength}</span>
                            </div>
                        }
                        <div>
                            <span>{t('start')}: </span>
                            <span>{(sectorInfoData.startPoint * 1000 + sectorInfoData.countdownPoint).toLocaleString()} м</span>
                        </div>
                        <div>
                            <span>{t('end')}: </span>
                            <span>{typeof sectorInfoData.startPoint === 'number' && ((sectorInfoData.startPoint * 1000 + sectorInfoData.countdownPoint) + (Number(sectorInfoData.length))).toLocaleString()} м</span>
                        </div>

                        <div>
                            <span>{t('total-length')}:</span>
                            <span>{sectorInfoData.length.toLocaleString()} м</span>
                        </div>
                    </SectorInfoWindow>
                </InfoWindow>

            </Map>
            <MapAttrGroup status={attrStatus}>
                <MapAttrGroupTitle onClick={() => setAttrStatus(!attrStatus)}>
                    {
                        categoryName ? categoryName : t('info')
                    }
                    {
                        attrStatus ? <ExpandSvg/> : <CollapseSvg/>
                    }
                </MapAttrGroupTitle>
                <MapAttrGroupItems>
                    {
                        isDataPending.$attributeList.data && isDataPending.$attributeList.data.length > 0 &&
                        isDataPending.$attributeList.data.map(item =>
                            <NavLink
                                key={item.id}
                                to={`/?attributeId=${item.id}${urlParams ? '&' + urlParams : ''}`}
                                isActive={() => {
                                    return Number(attributeId) === Number(item.id)
                                }}
                            >
                                {item.name}
                            </NavLink>
                        )
                    }
                </MapAttrGroupItems>
            </MapAttrGroup>
        </MapContainer>

    )
};

export const GoogleMapComponent = GoogleApiWrapper({
    apiKey: 'AIzaSyD5UhFY6pkdScRx8KcvTUu5bNFJ8CEtKRQ',
    language: 'ru',
})(GoogleMapComponentView);