import React, {useEffect, Fragment} from "react";
import {Redirect, withRouter} from "react-router-dom";
import {MainBlock} from "./main-block";
import {showOrganizationInfo} from "../model/events";
import {useUrlParams} from "../../../hooks/common";
import {useUserRole} from "../../../hooks/common";
import {USER_ROLES} from "../../../constants/user";
import {ReportPage} from "../../dashboard-report";

export const ContentView = (props) => {
    const {$userRole} = useUserRole()
    const {location} = props;
    const {info} = useUrlParams(location);
    useEffect(() => {
        if (info) {
            showOrganizationInfo(true)
        } else {
            showOrganizationInfo(false)
        }
    }, [info]);


    return (
        <Fragment>
            {info
                ? $userRole === null ? null : $userRole !== USER_ROLES.MANAGER ? (<ReportPage/>) :
                    <Redirect to='/'/>
                : (<MainBlock/>)
            }
        </Fragment>
    );
};

export const Content = withRouter(ContentView);


//    NOT WORK